import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import styles from "../../styles/Navbar.module.scss";

function LiElement({ icon, text, active }) {
    return <div className="d-flex flex-column justify-content-center align-items-center">
        <div className={`${active ? null : styles.icon} mb-2`}
            style={{
                width: 16,
                height: 16
            }}
        >
            {icon ? <FontAwesomeIcon icon={icon} /> : null}
        </div>

        <span className="mb-1">{text}</span>

        <div className={active ? styles.underlineActive : styles.underline}></div>
    </div>
}

export default LiElement;

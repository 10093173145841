import React from "react";

export default function PropIcon() {
  return (
    <svg
      version="1.0"
      xmlns="http://www.w3.org/2000/svg"
      width="100.000000pt"
      height="100.000000pt"
      viewBox="0 0 512.000000 512.000000"
      preserveAspectRatio="xMidYMid meet"
    >
      <g
        transform="translate(0.000000,512.000000) scale(0.100000,-0.100000)"
        fill="#fff"
        stroke="none"
      >
        <path
          d="M245 4973 c-44 -23 -74 -53 -96 -95 -18 -36 -19 -70 -19 -803 l0
-765 195 0 195 0 0 645 0 645 650 0 650 0 0 195 0 195 -772 0 c-685 -1 -776
-3 -803 -17z"
        />
        <path
          d="M3300 4795 l0 -195 650 0 650 0 0 -645 0 -645 195 0 195 0 0 765 c0
686 -2 769 -16 798 -23 45 -53 76 -96 98 -36 18 -70 19 -808 19 l-770 0 0
-195z"
        />
        <path
          d="M130 1045 c0 -733 1 -767 19 -803 22 -43 53 -73 98 -96 29 -14 113
-16 803 -16 l770 0 0 195 0 195 -650 0 -650 0 0 645 0 645 -195 0 -195 0 0
-765z"
        />
        <path
          d="M4600 1165 l0 -645 -650 0 -650 0 0 -195 0 -195 770 0 c690 0 774 2
803 16 45 23 76 53 98 96 18 36 19 70 19 803 l0 765 -195 0 -195 0 0 -645z"
        />
      </g>
    </svg>
  );
}

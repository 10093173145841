import React from "react";

export default function MouseIcon() {
  return (
    <svg
      version="1.0"
      xmlns="http://www.w3.org/2000/svg"
      width="100.000000pt"
      height="100.000000pt"
      viewBox="0 0 512.000000 512.000000"
      preserveAspectRatio="xMidYMid meet"
    >
      <g
        transform="translate(0.000000,512.000000) scale(0.100000,-0.100000)"
        fill="#fff"
        stroke="none"
      >
        <path
          d="M2430 5114 c-420 -48 -727 -190 -1004 -462 -271 -266 -432 -598 -476
-977 -13 -122 -13 -2108 0 -2230 43 -374 202 -705 466 -969 244 -243 554 -401
899 -457 103 -17 387 -17 490 0 730 118 1281 693 1365 1426 13 122 13 2108 0
2230 -43 371 -196 692 -458 959 -245 249 -532 399 -887 462 -80 14 -329 26
-395 18z m40 -346 l0 -173 -55 -24 c-64 -27 -136 -99 -168 -168 -21 -47 -22
-58 -22 -393 l0 -345 25 -50 c33 -68 114 -147 173 -170 l47 -18 0 -178 0 -179
-680 0 -680 0 0 253 c0 338 17 465 88 667 100 283 321 562 575 724 192 123
478 221 655 225 l42 1 0 -172z m341 156 c562 -96 1016 -523 1158 -1089 32
-129 41 -244 41 -512 l0 -253 -680 0 -680 0 0 179 0 178 47 18 c59 23 140 102
173 170 l25 50 0 345 c0 335 -1 346 -22 393 -32 69 -104 141 -168 168 l-55 24
0 173 0 172 33 0 c19 0 76 -7 128 -16z m-170 -509 c38 -20 77 -70 84 -108 3
-18 5 -163 3 -323 l-3 -291 -28 -36 c-36 -48 -79 -69 -137 -69 -58 0 -101 21
-137 69 l-28 36 -3 296 c-2 185 1 309 7 331 28 95 149 142 242 95z m1366
-2247 c-3 -717 -4 -735 -26 -833 -70 -314 -201 -549 -424 -762 -243 -232 -530
-361 -875 -394 -254 -24 -543 31 -773 147 -106 53 -251 157 -346 247 -223 213
-354 448 -424 762 -22 98 -23 116 -26 833 l-4 732 1451 0 1451 0 -4 -732z"
        />
      </g>
    </svg>
  );
}

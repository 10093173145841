import React from "react";

export default function KeyIcon() {
  return (
    <svg
      version="1.0"
      xmlns="http://www.w3.org/2000/svg"
      width="100.000000pt"
      height="100.000000pt"
      viewBox="0 0 512.000000 512.000000"
      preserveAspectRatio="xMidYMid meet"
    >
      <g
        transform="translate(0.000000,512.000000) scale(0.100000,-0.100000)"
        fill="#fff"
        stroke="none"
      >
        <path
          d="M3143 4930 c4 -18 7 -54 7 -80 l0 -47 70 -7 c150 -14 293 -78 410
-187 93 -84 109 -83 207 17 177 179 425 223 649 114 252 -122 375 -422 281
-683 -35 -96 -72 -153 -147 -226 -94 -91 -95 -109 -11 -201 76 -81 124 -163
158 -265 23 -69 27 -96 27 -205 0 -109 -4 -136 -27 -205 -36 -108 -89 -195
-168 -274 -79 -79 -166 -132 -274 -168 -69 -23 -96 -27 -205 -27 -108 0 -136
4 -205 27 -85 28 -173 76 -234 128 -105 90 -195 239 -225 374 -11 52 -22 76
-42 93 -48 42 -73 28 -232 -131 l-142 -142 -102 103 -103 102 142 142 c159
159 173 184 131 232 -17 20 -41 31 -93 42 -38 9 -106 33 -150 54 -420 202
-514 759 -184 1089 72 72 164 131 244 157 27 9 51 18 53 19 3 3 -36 146 -42
152 -2 2 -39 -10 -83 -26 -107 -42 -215 -115 -300 -204 -261 -275 -306 -682
-111 -1011 81 -136 238 -274 384 -336 l63 -26 -83 -83 -84 -84 -38 31 c-64 51
-126 73 -205 73 -262 -1 -394 -296 -227 -505 l31 -38 -1062 -1062 c-999 -999
-1061 -1063 -1061 -1096 0 -31 16 -51 183 -217 166 -166 186 -183 217 -183 31
0 53 19 257 222 l223 222 223 -222 c203 -202 226 -222 257 -222 31 0 49 15
177 143 183 183 182 171 41 314 l-102 104 62 61 61 62 104 -102 c143 -141 131
-142 314 41 128 128 143 146 143 177 0 31 -20 54 -222 257 l-223 223 368 368
c202 202 367 372 367 377 0 13 -92 105 -105 105 -6 0 -445 -435 -977 -967
l-968 -968 -102 103 -103 102 1022 1022 1023 1023 182 -181 c101 -100 197
-191 214 -202 47 -32 148 -55 203 -47 238 35 346 301 199 495 l-40 53 83 83
83 83 26 -62 c41 -97 96 -175 184 -263 282 -279 706 -323 1040 -107 176 114
303 291 362 503 28 100 26 310 -4 415 -30 105 -81 210 -141 289 l-49 64 25 24
c39 35 119 162 143 226 77 204 59 439 -47 622 -52 89 -181 217 -266 265 -38
21 -103 49 -145 63 -67 22 -94 26 -212 25 -119 0 -144 -3 -215 -26 -93 -31
-189 -85 -253 -143 l-46 -40 -63 47 c-123 93 -284 153 -442 166 l-84 6 6 -33z
m-613 -1834 c15 -3 130 -110 296 -278 287 -289 288 -289 276 -369 -6 -42 -69
-105 -111 -111 -80 -12 -80 -11 -369 276 -294 292 -300 301 -282 383 15 63 93
119 150 108 8 -2 26 -6 40 -9z m-585 -1956 l180 -180 -62 -62 -62 -62 -104
102 c-143 141 -131 142 -314 -41 -183 -183 -182 -171 -41 -314 l102 -104 -62
-62 -62 -62 -182 183 -183 182 300 300 c165 165 302 300 305 300 3 0 86 -81
185 -180z"
        />
        <path
          d="M3050 4546 c-235 -66 -375 -300 -315 -529 43 -165 157 -279 322 -322
244 -64 491 101 535 355 31 183 -65 376 -228 458 -101 51 -217 65 -314 38z
m235 -177 c91 -45 155 -149 155 -249 0 -150 -130 -280 -280 -280 -186 0 -319
182 -266 365 22 75 100 156 172 179 67 21 159 15 219 -15z"
        />
        <path
          d="M4140 4544 c-227 -82 -293 -362 -125 -529 66 -66 125 -90 225 -89 72
0 93 4 136 26 75 39 107 70 145 140 30 57 33 71 34 148 0 100 -24 159 -90 225
-82 82 -222 116 -325 79z m163 -158 c103 -43 128 -177 48 -257 -64 -65 -153
-65 -220 -1 -128 121 9 326 172 258z"
        />
        <path
          d="M4013 3585 c-162 -44 -276 -159 -318 -322 -63 -243 101 -491 353
-535 293 -50 554 210 504 502 -44 256 -295 421 -539 355z m232 -176 c91 -45
155 -149 155 -249 0 -150 -130 -280 -280 -280 -186 0 -319 182 -266 365 22 75
100 156 172 179 67 21 159 15 219 -15z"
        />
      </g>
    </svg>
  );
}

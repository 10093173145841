import React from "react";
import Style from "./Image.module.scss";

function Image({ image }) {
  return (
    <>
      <div
        className="row d-flex justify-content-center py-5"
        style={{
          position: "relative",
        }}
      >
        <img src={image} id={Style.img} alt="serwery minecraft 1.19" />
      </div>
    </>
  );
}

export default Image;

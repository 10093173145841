import React from "react";
import GamemodeHeader from "../../components/gamemode-pages/GamemodeHeader";
import GamemodeName from "../../components/gamemode-pages/GamemodeName";
import Image from "../../components/gamemode-pages/Image";
import HeadIcon from "../../components/gamemode-pages/creative/icons/HeadIcon";
import PropIcon from "../../components/gamemode-pages/creative/icons/PropIcon";
import GamemodeDescription from "../../components/gamemode-description/GamemodeDescription";

function Creative() {
  const sectionClassName = "d-flex flex-column justify-content-center align-items-center w-50 my-5"

  return <div className="d-flex flex-column justify-content-center align-items-center w-100">
    <GamemodeName name={"Creative"} />

    <GamemodeHeader
      heading={
        "Zrelaksuj się, bądź pochwal się swoimi umiejętnościami budowania na naszym trybie Creative."
      }
      subHeading={""}
    />

    <Image image={require("../../assets/img/creative.webp")} />

    <div className={sectionClassName}>
      <GamemodeDescription icon={<PropIcon />}
        header="DZIAŁKI"
        description={
          `Zaklep sobie działkę na której możesz zbudować co tylko przyjdzie Ci do głowy.`
        }
      />

      <GamemodeDescription icon={<HeadIcon />}
        header="GŁÓWKI"
        description={
          `Do budowania możesz użyć ponad 40 tysięcy główek, które mogą być dekoracjami w postaci picia, jedzenia, roślin itd.`
        }
      />
    </div>
  </div>
}

export default Creative;

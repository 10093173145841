import "./App.css";
import { BrowserRouter as Router, Routes, Route } from "react-router-dom";
import Statspage from "./pages/stats/Statspage";
import Homepage from "./pages/home-page/Homepage";
import Rulespage from "./pages/regulamin/Rulespage";
import {
  Creative,
  Duels,
  // Gildie,
  Minez,
  Skyblock,
  SMP,
} from "./pages/gamemode-pages";
import ScrollToTop from "./components/ScrollToTop";
import Navbar from "./components/navbar/Navbar";
import Dynmap from "./pages/dynmap/Dynmap";
import Footer from "./components/footer/Footer";
import Stats from "./pages/stats/Stats";
import UserStats from "./components/stats/UserStats";

function App() {
  return (
    <div className="container-fluid p-0 m-0 d-flex flex-column justify-items-center align-items-center w-100">
      <Router>
        <Navbar />

        <ScrollToTop />

        <Routes>
          <Route path="/" element={<Homepage />} />
          <Route path="/statystyki" element={<Statspage />} />
          {/* <Route
            path="/statystyki-minez"
            element={<Stats gameMode="MineZ" />}
          />
          <Route
            path="/statystyki-minez/:username"
            element={<UserStats gameMode="MineZ" />}
          /> */}
          <Route
            path="/statystyki-skymmo"
            element={<Stats gameMode="SkyMMO" />}
          />
          <Route
            path="/statystyki-skymmo/:username"
            element={<UserStats gameMode="SkyMMO" />}
          />
          <Route path="/regulamin" element={<Rulespage />} />
          <Route
            path="/mapa-minez"
            element={<Dynmap title="Mapa MineZ" src="https://dmap.jbwm.pl" />}
          />
          <Route
            path="/mapa-skymmo"
            element={
              <Dynmap title="Mapa SkyMMO" src="https://skymap.jbwm.pl" />
            }
          />
          <Route path="/creative" element={<Creative />} />
          <Route path="/duels" element={<Duels />} />
          {/* <Route path="/gildie" element={<Gildie />} /> */}
          <Route path="/minez" element={<Minez />} />
          <Route path="/skyblock" element={<Skyblock />} />
          <Route path="/smp" element={<SMP />} />
        </Routes>

        <Footer />
      </Router>
    </div>
  );
}

export default App;

import capitalizeFirstLetter from "../../utils/functions";

function Statistic({ name, level, exp }) {
  const progresBoxStyle = {
    borderRadius: 50,
    border: "1px solid rgb(255, 128, 0)",
  };

  const progresStyle = {
    width: `${exp}%`,
    backgroundColor: "rgb(255, 128, 0)",
    textAlign: "right",
    borderRadius: 50,
  };

  return (
    <div className="d-flex flex-column justify-content-center align-items-center w-100 py-2">
      <div className="fw-bold">{capitalizeFirstLetter(name)}</div>

      <div className="d-flex flex-row justify-content-around align-items-center w-100">
        <div>{level}</div>

        <div className="w-75 text-center" style={progresBoxStyle}>
          <div style={progresStyle}>
            <span className="px-2">{`${exp}%`}</span>
          </div>
        </div>

        <div>{level + 1}</div>
      </div>
    </div>
  );
}

export default Statistic;

import React from "react";
import GamemodeHeader from "../../components/gamemode-pages/GamemodeHeader";
import GamemodeName from "../../components/gamemode-pages/GamemodeName";
import Image from "../../components/gamemode-pages/gildie/Image";
import GamemodeDescription from "../../components/gamemode-description/GamemodeDescription";

function Gildie() {
  const sectionClassName = "d-flex flex-column justify-content-center align-items-center w-50 my-5"

  return <div className="d-flex flex-column justify-content-center align-items-center w-100">
    <GamemodeName name={"GILDIE"} />

    <GamemodeHeader
      heading={
        "Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed do eiusmod tempor incididunt ut labore et dolore magna aliqua."
      }
      subHeading={
        `Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed do eiusmod tempor incididunt ut labore et dolore magna aliqua. 
        Ut enim ad minim veniam, quis nostrud exercitation ullamco laboris nisi ut aliquip ex ea commodo consequat. 
        Duis aute irure dolor in reprehenderit in voluptate velit esse cillum dolore eu fugiat nulla pariatur.`
      }
    />

    <Image />

    <div className={sectionClassName}>
      <GamemodeDescription icon={null}
        header=""
        description={
          ``
        }
      />

      <GamemodeDescription icon={null}
        header=""
        description={
          ``
        }
      />

      <GamemodeDescription icon={null}
        header=""
        description={
          ``
        }
      />
    </div>
  </div>
}

export default Gildie;

function Dynmap({ title, src }) {
    const adClassName = "my-5"

    const adStyle = {
        width: "100%",
        height: "200px"
    }

    return <div className="d-flex flex-column justify-content-center align-items-center container"
        style={{
            minHeight: "100vh"
        }}
    >
        <div className={adClassName}
            style={adStyle}
        >
        </div>

        <h1 className="fancy text-white">
            <span>{title}</span>
        </h1>

        <iframe className="dynmap my-5"
            title={title}
            src={src}
            style={{
                height: 600,
                width: "100%"
            }}
        />

        <div className={adClassName}
            style={adStyle}
        >
        </div>
    </div>
}

export default Dynmap;

import React from "react";

export default function HeadIcon() {
  return (
    <svg
      version="1.0"
      xmlns="http://www.w3.org/2000/svg"
      width="100.000000pt"
      height="100.000000pt"
      viewBox="0 0 512.000000 512.000000"
      preserveAspectRatio="xMidYMid meet"
    >
      <g
        transform="translate(0.000000,512.000000) scale(0.100000,-0.100000)"
        fill="#fff"
        stroke="none"
      >
        <path
          d="M2477 4219 c-146 -154 -331 -491 -421 -767 l-18 -52 -66 61 c-145
134 -353 275 -529 360 -162 77 -187 68 -223 -82 -35 -149 -50 -292 -50 -482
l0 -177 -41 21 c-66 33 -257 105 -353 133 -208 61 -451 95 -498 70 -37 -19
-41 -43 -28 -165 37 -348 174 -708 395 -1039 114 -171 198 -273 345 -421 299
-301 669 -534 1030 -650 l74 -24 -45 -7 c-67 -10 -192 -9 -314 2 -598 57
-1171 369 -1487 812 -32 44 -58 84 -58 88 0 5 44 19 98 31 105 26 142 50 142
94 0 32 -41 75 -71 75 -13 0 -75 -14 -138 -31 -222 -59 -249 -86 -192 -193 50
-94 150 -230 244 -331 276 -297 664 -523 1082 -630 202 -52 341 -69 560 -69
200 1 313 14 470 56 108 29 242 29 350 0 157 -42 270 -55 470 -56 219 0 358
17 560 69 556 142 1041 486 1304 924 69 115 66 147 -16 182 -55 23 -259 79
-291 79 -31 0 -72 -43 -72 -75 0 -44 37 -68 143 -94 53 -12 97 -26 97 -31 0
-14 -110 -162 -174 -234 -244 -277 -644 -510 -1045 -610 -197 -50 -454 -76
-607 -61 l-79 7 105 38 c227 81 376 155 570 285 171 114 273 198 421 345 420
418 695 958 750 1474 16 153 3 172 -111 167 -197 -8 -506 -90 -736 -196 l-72
-33 -5 222 c-5 222 -22 358 -62 493 -27 92 -67 95 -225 15 -185 -92 -371 -220
-518 -357 l-60 -56 -17 53 c-84 255 -228 527 -381 719 -62 78 -98 109 -128
109 -12 0 -44 -24 -79 -61z m125 -186 c224 -295 386 -728 428 -1148 10 -97 14
-113 36 -132 14 -13 36 -23 50 -23 29 0 74 41 74 67 0 35 -29 254 -46 344
l-16 91 48 50 c66 67 232 203 324 265 88 59 249 147 256 141 27 -28 56 -480
40 -631 l-7 -66 -67 -39 c-232 -138 -564 -444 -741 -682 -108 -145 -212 -316
-280 -462 -52 -110 -118 -280 -128 -328 -7 -38 -19 -38 -26 0 -10 50 -77 220
-131 335 -65 137 -156 289 -254 424 l-76 104 -8 116 c-10 143 6 421 32 577 52
301 154 589 290 818 62 104 148 226 160 226 3 0 22 -21 42 -47z m-1164 -377
c148 -75 389 -254 511 -380 l45 -45 -17 -89 c-26 -130 -44 -298 -52 -464 l-7
-148 -106 101 c-113 108 -311 262 -416 323 l-65 38 -7 65 c-9 85 2 453 15 518
6 28 13 65 16 83 3 17 8 32 11 32 2 0 35 -15 72 -34z m-858 -528 c321 -62 678
-235 980 -475 109 -86 327 -304 413 -413 270 -339 444 -730 501 -1124 l5 -38
-47 7 c-112 16 -255 50 -367 86 -151 50 -408 176 -545 266 -585 387 -996 985
-1106 1608 -8 44 -14 88 -14 98 0 20 -2 20 180 -15z m4140 11 c0 -49 -54 -282
-89 -389 -277 -830 -1058 -1517 -1879 -1655 -57 -9 -105 -15 -107 -13 -2 2 5
53 15 113 128 742 699 1454 1435 1787 183 82 365 137 575 171 47 8 50 7 50
-14z"
        />
      </g>
    </svg>
  );
}

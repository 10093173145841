import React from "react";
import GamemodeHeader from "../../components/gamemode-pages/GamemodeHeader";
import GamemodeName from "../../components/gamemode-pages/GamemodeName";
import KeyIcon from "../../components/gamemode-pages/smp/icons/KeyIcon";
import MapIcon from "../../components/gamemode-pages/smp/icons/MapIcon";
import Image from "../../components/gamemode-pages/Image";
import GamemodeDescription from "../../components/gamemode-description/GamemodeDescription";

function SMP() {
  const sectionClassName = "d-flex flex-column justify-content-center align-items-center w-50 my-5"

  return <div className="d-flex flex-column justify-content-center align-items-center w-100">
    <GamemodeName name={"Lifesteal SMP"} />

    <GamemodeHeader
      heading={
        `Dziesięc serc ci nie wystarcza? To zdobądź dodatkowe serca wydzierając je od poległych przeciwników! 
        Możesz posiadać aż do 50 serc jednocześnie. Uważaj jednak aby nie stracić wszystkich serc po ich utracie otrzymasz siedmiodniowego bana!`
      }
      subHeading={""}
    />

    <Image image={require("../../assets/img/smp.webp")} />

    <div className={sectionClassName}>
      <GamemodeDescription icon={<KeyIcon />}
        header="Nie chce ci się zbierać ekwipunku?"
        description={
          `Codzienie odbierzesz darmowy klucz do skrzyń premium!`
        }
      />

      <GamemodeDescription icon={<MapIcon />}
        header="Mapa z borderem aż 10000 na 10000 kratek!"
        description={
          `Aż się prosi o grief. Nether oraz End otwarty dla każdego.`
        }
      />
    </div>
  </div>

  //   return (
  //     <>
  //       <div
  //         className="home_container"
  //         style={{
  //           width: "100%",
  //           padding: 20,
  //           boxSizing: "border-box",
  //         }}
  //       >
  //         <GamemodeName name={"Lifesteal SMP"} />
  //         <GamemodeHeader
  //           heading={
  //             "Dziesięc serc ci nie wystarcza? To zdobądź dodatkowe serca wydzierając je od poległych przeciwników! Możesz posiadać aż do 50 serc jednocześnie. Uważaj jednak aby nie stracić wszystkich serc po ich utracie otrzymasz siedmiodniowego bana!"
  //           }
  //           subHeading={""}
  //         />
  //         <Image image={require("../../assets/img/smp.webp")} />
  //         <ImageWithText
  //           icon={<KeyIcon />}
  //           title={"Nie chce ci się zbierać ekwipunku?"}
  //           content={"Codzienie odbierzesz darmowy klucz do skrzyń premium!"}
  //         />
  //         <ImageWithText
  //           icon={<MapIcon />}
  //           title={"Mapa z borderem aż 10000 na 10000 kratek!"}
  //           content={"Aż się prosi o grief. Nether oraz End otwarty dla każdego."}
  //         />
  //       </div>
  //     </>
  //   );
}

export default SMP;

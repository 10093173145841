import styles from "./top10.module.scss";
import capitalizeFirstLetter from "../../utils/functions";

function Top10({ category, users }) {
  return users ? (
    <div className="d-flex flex-column justify-content-center align-items-center text-white w-100 my-5">
      <h2 className={`${styles.category} mb-5`}>
        {capitalizeFirstLetter(category)}
      </h2>

      {Object.entries(users).map(([key, value], index) => {
        return (
          <div
            key={index}
            className={`${styles.record} d-flex flex-row justify-content-between align-items-center w-100 py-1`}
          >
            <div
              className="fw-bold"
              style={{
                width: 50,
              }}
            >
              #{index + 1}
            </div>

            <div
              className="d-flex justify-content-center"
              style={{
                width: 50,
              }}
            >
              <img src={`https://minotar.net/avatar/${key}/50`} alt="user" />
            </div>

            <div className="w-25 text-center">{key}</div>

            <div className="w-25 text-end">{value}</div>
          </div>
        );
      })}
    </div>
  ) : (
    <></>
  );
}

export default Top10;

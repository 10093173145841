import React from "react";

export default function TowerIcon() {
  return (
    <svg
      height="130pt"
      version="1.0"
      xmlns="http://www.w3.org/2000/svg"
      viewBox="0 0 656.000000 1700.000000"
      preserveAspectRatio="xMidYMid meet"
    >
      <g
        transform="translate(0.000000,1700.000000) scale(0.100000,-0.100000)"
        fill="#fff"
        stroke="none"
      >
        <path
          d="M3185 16717 c-38 -18 -60 -43 -80 -90 -13 -31 -15 -120 -15 -642 l0
-605 -276 0 c-313 0 -326 -3 -372 -73 l-27 -41 -3 -618 -3 -618 -257 0 c-156
0 -273 -4 -297 -11 -50 -14 -100 -64 -114 -114 -7 -27 -11 -235 -11 -637 l0
-598 -157 0 c-180 0 -219 -11 -263 -77 l-25 -37 -3 -4168 -2 -4168 -193 0
c-105 0 -208 -5 -227 -10 -41 -11 -94 -61 -109 -103 -8 -19 -11 -259 -11 -728
l0 -699 -115 0 c-132 0 -176 -15 -217 -72 l-23 -33 -3 -818 c-3 -893 -5 -845
55 -901 14 -13 44 -29 67 -35 30 -9 777 -11 2776 -9 l2735 3 35 22 c20 12 46
40 58 62 l22 39 -2 819 -3 818 -23 33 c-41 57 -85 72 -217 72 l-115 0 0 699
c0 469 -3 709 -11 728 -15 42 -68 92 -109 103 -19 5 -122 10 -227 10 l-193 0
-2 4168 -3 4167 -23 37 c-41 65 -84 78 -265 78 l-157 0 0 598 c0 402 -4 610
-11 637 -14 50 -64 100 -114 114 -24 7 -141 11 -297 11 l-258 0 0 607 c0 595
0 608 -21 650 -11 23 -35 53 -52 65 -31 22 -39 23 -319 26 l-288 3 0 612 0
612 -23 39 c-12 22 -34 48 -49 59 -34 26 -121 33 -163 14z m585 -2177 l0 -510
-515 0 -515 0 0 510 0 510 515 0 515 0 0 -510z m678 -1357 l2 -513 -1195 0
-1195 0 0 508 c0 280 3 512 7 515 3 4 540 6 1192 5 l1186 -3 3 -512z m452
-4903 l0 -4060 -1645 0 -1645 0 0 4060 0 4060 1645 0 1645 0 0 -4060z m540
-4995 l0 -605 -2185 0 -2185 0 0 605 0 605 2185 0 2185 0 0 -605z m360 -1540
l0 -605 -2545 0 -2545 0 0 605 0 605 2545 0 2545 0 0 -605z"
        />
        <path d="M2250 11500 l0 -160 335 0 335 0 0 160 0 160 -335 0 -335 0 0 -160z" />
        <path d="M3590 11500 l0 -160 335 0 335 0 0 160 0 160 -335 0 -335 0 0 -160z" />
        <path d="M2250 10215 l0 -165 335 0 335 0 0 165 0 165 -335 0 -335 0 0 -165z" />
        <path d="M3590 10215 l0 -165 335 0 335 0 0 165 0 165 -335 0 -335 0 0 -165z" />
        <path d="M2250 8925 l0 -165 335 0 335 0 0 165 0 165 -335 0 -335 0 0 -165z" />
        <path d="M3590 8925 l0 -165 335 0 335 0 0 165 0 165 -335 0 -335 0 0 -165z" />
        <path d="M2250 7635 l0 -165 335 0 335 0 0 165 0 165 -335 0 -335 0 0 -165z" />
        <path d="M3590 7635 l0 -165 335 0 335 0 0 165 0 165 -335 0 -335 0 0 -165z" />
        <path d="M2250 6345 l0 -165 335 0 335 0 0 165 0 165 -335 0 -335 0 0 -165z" />
        <path d="M3590 6345 l0 -165 335 0 335 0 0 165 0 165 -335 0 -335 0 0 -165z" />
        <path d="M2250 5055 l0 -165 335 0 335 0 0 165 0 165 -335 0 -335 0 0 -165z" />
        <path d="M3590 5055 l0 -165 335 0 335 0 0 165 0 165 -335 0 -335 0 0 -165z" />
      </g>
    </svg>
  );
}

import React from "react";

export default function UpgradeIcon() {
  return (
    <svg
      version="1.0"
      xmlns="http://www.w3.org/2000/svg"
      viewBox="0 0 512.000000 512.000000"
      preserveAspectRatio="xMidYMid meet"
      height="80pt"
    >
      <g
        transform="translate(0.000000,512.000000) scale(0.100000,-0.100000)"
        fill="#fff"
        stroke="none"
      >
        <path
          d="M2142 4964 c-16 -11 -22 -25 -22 -51 0 -32 11 -47 107 -144 l107
-109 -41 0 c-123 0 -437 -74 -623 -147 -751 -294 -1289 -966 -1415 -1768 -22
-137 -30 -425 -16 -555 18 -172 51 -330 73 -352 27 -27 76 -20 101 13 18 26
18 30 4 95 -44 190 -60 502 -37 694 80 655 444 1231 997 1573 236 146 510 248
783 292 171 27 169 30 57 -83 -83 -84 -98 -104 -99 -132 -1 -23 6 -39 22 -52
48 -39 63 -29 249 156 154 153 174 178 174 206 0 28 -20 53 -174 206 -184 183
-199 192 -247 158z"
        />
        <path
          d="M3302 4484 c-23 -16 -30 -66 -13 -92 4 -7 57 -37 117 -66 588 -284
1009 -805 1157 -1431 85 -357 74 -749 -29 -1095 -33 -110 -103 -290 -113 -290
-4 0 -23 57 -41 127 -19 72 -41 134 -52 145 -32 32 -91 18 -108 -27 -11 -29
104 -470 130 -498 9 -10 25 -19 36 -19 40 -3 459 115 482 136 31 27 26 72 -10
99 l-27 20 -142 -37 c-77 -21 -143 -36 -145 -33 -2 2 14 44 35 94 222 511 238
1137 44 1663 -202 549 -605 998 -1127 1256 -72 35 -139 64 -151 64 -11 0 -31
-7 -43 -16z"
        />
        <path
          d="M2262 3834 c-35 -24 -46 -53 -57 -148 l-10 -90 -105 -36 c-58 -20
-136 -53 -175 -73 -38 -20 -73 -37 -77 -37 -4 0 -33 22 -65 48 -70 59 -94 72
-128 72 -45 0 -76 -24 -212 -163 -131 -133 -133 -136 -133 -180 0 -46 27 -94
97 -168 l24 -26 -51 -104 c-28 -57 -60 -135 -72 -174 -11 -38 -23 -73 -27 -77
-3 -4 -47 -12 -96 -17 -101 -12 -133 -29 -152 -82 -15 -43 -17 -349 -3 -401
14 -49 49 -75 110 -82 28 -3 72 -9 98 -12 l48 -5 17 -67 c13 -51 80 -203 124
-283 2 -3 -24 -39 -57 -80 -55 -67 -60 -77 -60 -122 l0 -49 130 -133 c72 -73
146 -140 164 -150 49 -27 91 -14 169 50 37 30 70 55 74 55 3 0 47 -20 97 -44
50 -24 130 -57 177 -72 l87 -28 6 -85 c14 -163 27 -171 286 -171 259 0 272 8
286 172 l6 85 89 27 c49 15 128 48 176 72 49 24 92 44 96 44 5 0 39 -25 75
-55 72 -59 115 -74 161 -55 42 17 298 280 303 310 7 50 -5 80 -62 150 l-59 71
50 102 c27 56 59 134 71 172 11 39 24 73 28 78 3 4 47 12 96 18 158 18 166 34
162 299 -3 173 -5 191 -24 217 -23 32 -67 47 -167 60 l-68 8 -29 87 c-16 48
-50 128 -75 177 l-45 90 52 62 c60 73 79 119 67 163 -10 34 -245 277 -291 302
-47 25 -81 17 -146 -31 -32 -24 -65 -51 -72 -60 -18 -21 -27 -19 -105 22 -39
20 -117 53 -175 73 l-105 36 -11 99 c-11 92 -14 101 -43 127 l-31 28 -208 0
c-171 0 -211 -3 -230 -16z m384 -224 c6 -52 14 -103 18 -113 5 -10 31 -23 65
-33 148 -40 241 -80 377 -159 42 -25 66 -16 153 55 l74 61 99 -98 c54 -54 98
-101 98 -105 0 -5 -28 -42 -61 -83 -38 -46 -62 -84 -61 -99 0 -14 20 -60 44
-103 53 -92 93 -188 117 -285 27 -107 22 -104 204 -124 l57 -6 0 -143 0 -143
-77 -7 c-90 -8 -149 -21 -158 -36 -4 -5 -18 -51 -31 -102 -22 -82 -87 -229
-144 -327 -26 -45 -21 -59 51 -147 33 -39 59 -75 59 -80 0 -5 -44 -52 -98
-106 l-98 -98 -80 65 c-46 38 -89 66 -102 66 -13 0 -54 -18 -90 -40 -87 -51
-189 -94 -284 -119 -126 -33 -121 -26 -136 -181 l-7 -75 -145 0 -145 0 -10
103 c-11 111 -20 126 -75 138 -112 24 -241 77 -375 154 -54 30 -73 24 -164
-50 l-74 -61 -99 98 c-54 54 -98 101 -98 106 0 5 26 41 59 80 72 88 77 102 51
147 -57 98 -122 245 -144 327 -13 51 -27 97 -31 102 -9 15 -68 29 -157 36
l-78 7 0 143 0 143 58 6 c131 15 151 18 166 29 9 7 28 55 42 107 22 84 86 231
144 330 26 44 21 59 -51 147 -33 40 -59 76 -59 81 0 4 45 52 99 106 l99 98 81
-66 c45 -36 91 -66 102 -66 12 0 48 16 80 35 86 53 176 91 291 125 57 16 108
37 114 45 7 9 15 57 19 108 4 51 9 95 12 100 2 4 68 6 146 5 l142 -3 11 -95z"
        />
        <path
          d="M2397 3190 c-470 -57 -796 -496 -713 -960 36 -199 149 -384 311 -507
142 -108 309 -163 495 -163 144 0 239 22 365 84 489 242 607 884 235 1288 -70
77 -195 164 -288 201 -121 49 -281 72 -405 57z m267 -156 c239 -62 441 -273
492 -515 11 -53 14 -104 11 -179 -9 -182 -68 -313 -201 -446 -132 -132 -290
-197 -476 -197 -116 0 -197 19 -310 75 -114 56 -238 177 -293 288 -100 202
-102 415 -5 619 33 68 56 101 127 171 115 116 237 178 396 203 50 8 195 -2
259 -19z"
        />
        <path
          d="M552 1363 c-17 -14 -35 -68 -82 -243 -33 -124 -60 -235 -60 -247 0
-25 43 -63 72 -63 39 0 62 41 97 178 19 72 37 132 41 132 4 0 38 -42 76 -92
89 -121 293 -324 419 -419 317 -237 663 -385 1040 -444 141 -23 406 -30 550
-16 467 45 908 233 1262 535 132 113 146 138 103 184 -36 39 -72 29 -147 -39
-327 -297 -743 -484 -1193 -539 -124 -15 -372 -12 -506 5 -550 72 -1049 354
-1396 789 -44 54 -77 100 -75 102 2 3 65 -12 140 -32 130 -35 138 -36 161 -20
36 23 43 61 19 94 -17 23 -50 34 -234 84 -280 76 -261 73 -287 51z"
        />
      </g>
    </svg>
  );
}

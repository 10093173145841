import React from "react";

export default function PlanetIcon() {
  return (
    <svg
      version="1.0"
      xmlns="http://www.w3.org/2000/svg"
      viewBox="0 0 200.000000 200.000000"
      preserveAspectRatio="xMidYMid meet"
    >
      <g
        transform="translate(0.000000,200.000000) scale(0.100000,-0.100000)"
        fill="#fff"
        stroke="none"
      >
        <path
          d="M75 1615 c-136 -49 -85 -196 146 -420 214 -207 504 -404 849 -576
567 -283 982 -329 921 -101 -20 74 -93 172 -223 298 l-123 117 -17 -77 -18
-77 26 -41 c164 -259 -309 -182 -814 133 -152 94 -266 182 -362 279 -167 167
-170 269 -10 270 52 1 58 4 109 50 30 28 53 52 50 54 -13 12 -241 78 -315 91
-106 18 -167 18 -219 0z"
        />
        <path
          d="M890 1595 c-170 -32 -343 -159 -420 -306 l-34 -66 24 -29 c85 -102
343 -288 550 -397 128 -68 302 -140 393 -163 65 -16 68 -16 88 2 28 25 86 147
104 219 19 75 19 202 0 283 -74 313 -388 517 -705 457z"
        />
        <path
          d="M400 947 c0 -124 65 -262 175 -372 91 -92 165 -136 281 -165 134 -35
300 -18 415 40 l42 22 -74 32 c-256 113 -616 321 -790 457 -20 16 -40 29 -43
29 -3 0 -6 -19 -6 -43z"
        />
      </g>
    </svg>
  );
}

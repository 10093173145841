import React from "react";
import styles from "../../styles/Footer.module.scss";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faDiscord, faFacebook } from "@fortawesome/free-brands-svg-icons";
import { useNavigate } from "react-router-dom";

function Footer({ whyUs }) {
  let navigate = useNavigate();

  return (
    <>
      {whyUs ? (
        <div
          className="container"
          id={styles.WhyUsContainer}
          style={{ paddingTop: 150 }}
        >
          <p className={styles.WhyUsHeading}>Dlaczego my?</p>
          <p className={styles.WhyUsParagraph}>
            Staramy stworzyć się coś nowego, oryginalnego aby odświeżyć
            Polskiego minecrafta. Za długo jesteśmy zalewani tymi samymi
            serwerami bez większego wkładu. Uruchamiamy nowe przełomowe tryby,
            tworzymy najlepsze community. Jeśli szukasz serwera na dłużej dobrze
            trafiłeś! Autorskie pluginy, budowle oraz tryby zapewnią Ci rozrywkę
            na dłuuugie godziny. Wpadnij i sam oceń!
          </p>
        </div>
      ) : null}
      <div className="container mb-5" id={styles.container}>
        <div className="row">
          <div
            className="col-lg-2 col-12 text-white text-center py-5 "
            id={styles.copyright}
          >
            <p className="p-0 m-0">Copyright © JBWM 2022.</p>
            <p className="p-0 m-0">Wszelkie prawa zastrzeżone.</p>
          </div>
          <div
            className="col-lg-3 col-sm-6 col-12 text-white"
            id={styles.links}
          >
            <h5 className="text-center text-sm-start">Szybkie linki</h5>
            <ul className="text-center text-sm-start">
              <li onClick={() => navigate("/")} style={{ cursor: "pointer" }}>
                Strona główna
              </li>
              <li
                onClick={() => navigate("/regulamin")}
                style={{ cursor: "pointer" }}
              >
                Regulamin
              </li>
              <li>
                <a
                  href="https://jbwm.pl/sklep/"
                  style={{ textDecoration: "none", cursor: "pointer" }}
                >
                  Sklep
                </a>
              </li>
              <li
                onClick={() => navigate("/statystyki")}
                style={{ cursor: "pointer" }}
              >
                Statystyki
              </li>
              <li
                onClick={() => navigate("/mapa-minez")}
                style={{ cursor: "pointer" }}
              >
                Minez Dynmap
              </li>
              <li
                onClick={() => navigate("/lista-banow")}
                style={{ cursor: "pointer" }}
              >
                Lista banów
              </li>
              <li>
                <a href="https://discord.com/invite/NRDwwyk" target="blank">
                  Discord
                </a>
              </li>
              <li onClick={() => navigate("/mapa-skymmo")}
                style={{ cursor: "pointer" }}
              >
                Mapa SkyMMO
              </li>
            </ul>
          </div>
          <div
            className="col-lg-3 col-sm-6 col-12 text-white"
            id={styles.versions}
          >
            <h5 className="text-center text-sm-start">Wspieramy wersje</h5>
            <ul className="text-center text-sm-start">
              <li>
                <a
                  href="https://topkaminecraft.pl/version/MC_1_18"
                  target="blank"
                >
                  Serwery Minecraft 1.18
                </a>
              </li>
              <li>
                <a
                  href="https://topkaminecraft.pl/version/MC_1_17"
                  target="blank"
                >
                  Serwery Minecraft 1.17
                </a>
              </li>
              <li>
                <a
                  href="https://topkaminecraft.pl/version/MC_1_16_5"
                  target="blank"
                >
                  Serwery Minecraft 1.16.5
                </a>
              </li>
              <li>
                <a
                  href="https://topkaminecraft.pl/version/MC_1_16"
                  target="blank"
                >
                  Serwery Minecraft 1.16
                </a>
              </li>
              <li>
                <a
                  href="https://topkaminecraft.pl/version/MC_1_12_2"
                  target="blank"
                >
                  Serwery Minecraft 1.12.2
                </a>
              </li>
              <li>
                <a
                  href="https://topkaminecraft.pl/version/MC_1_12"
                  target="blank"
                >
                  Serwery Minecraft 1.12
                </a>
              </li>
              <li>
                <a
                  href="https://topkaminecraft.pl/version/MC_1_9"
                  target="blank"
                >
                  Serwery Minecraft 1.9
                </a>
              </li>
              <li>
                <a
                  href="https://topkaminecraft.pl/version/MC_1_8_8"
                  target="blank"
                >
                  Serwery Minecraft 1.8.8
                </a>
              </li>
              <li>
                <a
                  href="https://topkaminecraft.pl/version/MC_1_8"
                  target="blank"
                >
                  Serwery Minecraft 1.8
                </a>
              </li>
            </ul>
          </div>
          <div
            className="col-lg-3 col-sm-6 col-12 text-white"
            id={styles.gamemodes}
          >
            <h5 className="text-center text-sm-start">
              Nasze serwery Minecraft
            </h5>
            <ul className="text-center text-sm-start">
              <li>
                <a
                  href="https://topkaminecraft.pl/category/skyblock"
                  target="blank"
                >
                  Serwery Skyblock
                </a>
              </li>
              <li>
                <a
                  href="https://topkaminecraft.pl/category/survival"
                  target="blank"
                >
                  Serwery Survival
                </a>
              </li>
              <li>
                <a
                  href="https://topkaminecraft.pl/category/bedwars"
                  target="blank"
                >
                  Serwery Bedwars
                </a>
              </li>
              <li>
                <a
                  href="https://topkaminecraft.pl/category/duels"
                  target="blank"
                >
                  Serwery Duels
                </a>
              </li>
              <li>
                <a href="https://topkaminecraft.pl/category/smp" target="blank">
                  Serwery SMP
                </a>
              </li>
              <li>
                <a
                  href="https://topkaminecraft.pl/category/creative"
                  target="blank"
                >
                  Serwery CREATIVE
                </a>
              </li>
              <li>
                <a href="https://topkaminecraft.pl/category/pvp" target="blank">
                  Serwery PVP
                </a>
              </li>
            </ul>
          </div>
          <div className="col-lg-1 col-sm-6 col-12 text-white">
            <h5 className="text-center text-sm-start">Kontakt</h5>
            <div className="text-center text-sm-start">
              <a href="https://discord.com/invite/NRDwwyk">
                <FontAwesomeIcon
                  icon={faDiscord}
                  style={{
                    color: "#fff",
                    fontSize: 20,
                    paddingRight: 20,
                    paddingTop: 10,
                  }}
                />
              </a>
              <a href="https://www.facebook.com/Jbwmpl/">
                <FontAwesomeIcon
                  icon={faFacebook}
                  style={{
                    color: "#fff",
                    fontSize: 20,
                    paddingTop: 30,
                  }}
                />
              </a>
            </div>
          </div>
        </div>
      </div>
    </>
  );
}

export default Footer;

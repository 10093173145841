import React from "react";

export default function BookIcon() {
  return (
    <svg
      version="1.0"
      xmlns="http://www.w3.org/2000/svg"
      height="80pt"
      viewBox="0 0 791.000000 854.000000"
      preserveAspectRatio="xMidYMid meet"
    >
      <g
        transform="translate(0.000000,854.000000) scale(0.100000,-0.100000)"
        fill="#fff"
        stroke="none"
      >
        <path
          d="M3390 8514 c-55 -20 -101 -53 -161 -119 -25 -27 -394 -437 -819 -910
-426 -473 -1069 -1188 -1429 -1589 -361 -401 -675 -754 -699 -785 -68 -89
-167 -300 -206 -437 -52 -183 -87 -468 -54 -448 8 5 9 2 4 -11 -4 -11 -10 -69
-13 -131 -14 -267 57 -577 179 -779 l40 -68 -21 -36 c-88 -147 -154 -312 -182
-456 -23 -116 -18 -415 9 -550 82 -409 277 -692 587 -852 49 -25 210 -82 415
-148 776 -248 1752 -562 2850 -916 804 -260 849 -273 920 -273 86 1 144 22
203 74 38 33 241 278 1429 1728 137 166 340 414 451 550 112 136 374 454 582
707 245 298 386 479 403 515 20 44 26 70 26 130 0 68 -5 85 -46 175 -88 194
-117 321 -125 555 -8 256 33 508 128 774 34 95 39 120 39 197 0 77 -3 93 -30
144 -41 79 -41 161 1 322 49 189 34 284 -62 388 -88 95 -244 127 -360 73 -66
-30 -129 -89 -159 -148 -32 -63 -87 -292 -101 -421 -9 -91 -9 -137 0 -212 l12
-96 -458 -568 c-772 -956 -1711 -2117 -1881 -2328 -115 -141 -168 -199 -180
-197 -17 4 -356 107 -2598 796 -656 201 -1205 366 -1222 366 -66 0 -150 140
-177 294 -19 109 -20 340 -2 417 28 116 90 186 204 229 116 44 136 41 463 -58
569 -173 959 -292 1355 -412 220 -67 697 -212 1060 -322 877 -267 815 -252
911 -223 65 19 111 60 237 208 266 314 1229 1452 1297 1532 42 50 402 475 800
945 398 470 750 886 782 923 89 104 103 136 103 237 0 73 -4 92 -27 136 -30
57 -94 119 -144 140 -30 13 -493 119 -3144 720 -327 74 -693 157 -813 185
-232 52 -302 58 -377 33z m1138 -1370 c681 -165 1109 -273 1130 -286 39 -24
52 -46 52 -90 0 -28 -19 -57 -107 -168 -147 -184 -187 -223 -254 -246 -92 -32
-175 -25 -414 35 -115 29 -599 147 -1074 262 -474 115 -876 216 -891 224 -39
20 -70 62 -70 94 0 33 6 41 154 224 144 178 197 213 334 216 26 1 495 -108
1140 -265z m2566 -2926 c10 -76 51 -234 87 -333 17 -47 29 -92 26 -99 -5 -12
-318 -396 -917 -1126 -80 -98 -1082 -1321 -1427 -1742 -84 -103 -157 -189
-163 -193 -11 -7 51 -26 -1065 330 -495 157 -1111 354 -1370 436 -259 83 -666
213 -906 290 -239 76 -440 139 -446 139 -14 0 -99 86 -132 134 -78 115 -106
226 -106 431 0 177 11 222 77 333 28 46 37 53 58 49 21 -3 1843 -567 3440
-1064 282 -87 489 -147 526 -150 74 -7 153 14 211 56 24 17 261 302 564 678
287 356 559 693 604 748 102 126 213 264 604 748 171 213 316 387 320 387 5 0
12 -24 15 -52z"
        />
      </g>
    </svg>
  );
}

import React from "react";

function GamemodeName({ name }) {
  return (
    <div className="container mt-5">
      <div className="text-center mb-5">
        <h1 className="fancy pb-3" style={{ color: "#fff" }}>
          <span>{name}</span>
        </h1>
      </div>
    </div>
  );
}

export default GamemodeName;

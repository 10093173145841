import React from "react";
import HeroContent from "./HeroContent";

function Herosection() {
  const [coords, setCoords] = React.useState({ x: 0, y: 0 });

  const handleMouseMove = (e) => {
    setCoords({
      x: -e.clientX,
      y: -e.clientY,
    });
  };

  return (
    <div
      className="home_container"
      style={{
        width: "100%",
        padding: 20,
        boxSizing: "border-box",
      }}
      onMouseMove={handleMouseMove}
    >
      <div
        className="bw-23c"
        style={{
          marginTop: 50,
          position: "absolute",
          top: 0,
          left: 0,
          width: "95%",
          height: "2800px",
          zIndex: -1,
          transform: `translate(${-coords.x / 50}px,${-coords.y / 50}px)`,
        }}
      ></div>
      <HeroContent coords={coords} />
    </div>
  );
}

export default Herosection;

import React from "react";
import styles from "../../styles/Navbar.module.scss";
import { useNavigate, useLocation } from "react-router-dom";
import LiElement from "../li-element/LiElement";
import { faCoins } from "@fortawesome/free-solid-svg-icons";

function getWindowDimensions() {
  const { innerWidth: width, innerHeight: height } = window;
  return {
    width,
    height,
  };
}

function Navbar() {
  const [online, setOnline] = React.useState("");

  async function fetchData() {
    try {
      const response = await fetch("https://mcapi.jbwm.pl/online");
      const data = await response.json();
      setOnline(data);
    } catch (error) {
      console.log(error);
    }
  }

  let navigate = useNavigate();
  const [modalOpened, setModalOpened] = React.useState(false);
  const [windowDimensions, setWindowDimensions] = React.useState(
    getWindowDimensions()
  );

  React.useEffect(() => {
    fetchData();

    function handleResize() {
      setWindowDimensions(getWindowDimensions());
    }

    window.addEventListener("resize", handleResize);
    return () => window.removeEventListener("resize", handleResize);
  }, []);

  let { width } = windowDimensions;

  const close = () => setModalOpened(false);
  const open = () => setModalOpened(true);

  const location = useLocation();

  const liClassName = "mx-3 my-1"

  return <div id={styles.navWrapper}
    className="d-flex flex-column flex-xl-row justify-content-between align-items-center w-75"
  >
    <div className="d-flex flex-row justify-content-center align-items-center">
      <img
        src={require("../../assets/img/logo.webp")}
        alt="a"
        onClick={() => navigate("/")}
        style={{ cursor: "pointer" }}
      />

      <div className={`${styles.pulse} ms-5`} />

      <span className="text-white"
        style={{
          fontSize: 14
        }}
      >
        online {online} / 900
      </span>
    </div>

    <ul className="d-flex flex-column flex-md-row justify-content-center align-items-center text-white list-unstyled">
      <li className={liClassName}
        onClick={() => navigate("/")}
      >
        <LiElement icon={null}
          text="Strona główna"
          active={location.pathname === "/"}
        />
      </li>

      <li className={liClassName}>
        <a href="https://jbwm.pl/sklep/"
          style={{
            textDecoration: "none",
            color: "#fff"
          }}
        >
          <LiElement icon={faCoins}
            text="Sklep"
          />
        </a>
      </li>

      <li className={liClassName}
        onClick={() => navigate("/statystyki")}
      >
        <LiElement icon={null}
          text="Statystyki"
          active={location.pathname.includes("statystyki")}
        />
      </li>

      <li className={liClassName}
        onClick={() => navigate("/mapa-minez")}
      >
        <LiElement icon={null}
          text="Mapa MineZ"
          active={location.pathname === "/mapa-minez"}
        />
      </li>

      <li className={liClassName}
        onClick={() => navigate("/mapa-skymmo")}
      >
        <LiElement icon={null}
          text="Mapa SkyMMO"
          active={location.pathname === "/mapa-skymmo"}
        />
      </li>

      <li className={liClassName}
        onClick={() => navigate("/regulamin")}
      >
        <LiElement icon={null}
          text="Regulamin"
          active={location.pathname === "/regulamin"}
        />
      </li>
    </ul>
  </div>
}

export default Navbar;

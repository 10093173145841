import React from "react";
import GamemodeHeader from "../../components/gamemode-pages/GamemodeHeader";
import GamemodeName from "../../components/gamemode-pages/GamemodeName";
import styles from "../../styles/Minez.module.scss";
import GamemodeDescription from "../../components/gamemode-description/GamemodeDescription";
import { BookIcon, MapIcon, SkullIcon, TowerIcon } from "../../components/gamemode-pages/minez/icons";

function Minez() {
  const sectionClassName = "d-flex flex-column flex-lg-row justify-content-center align-items-center w-75 my-5"
  const sectionItemClassName = "col-12 col-lg-6 mx-5 d-flex justify-content-center align-items-center"
  const descriptionSectionItemClassName = `${sectionItemClassName} d-flex flex-column justify-content-center align-items-center`
  const imgClassName = "col-12 col-lg-8"

  return <div className="d-flex flex-column justify-content-center align-items-center w-100">
    <GamemodeName name="MineZ" />

    <GamemodeHeader
      heading={
        `Eksploruj rozbudowaną postapokaliptyczną mapę w poszukiwaniu skrzyń z łupami, 
        aby przetrwać w brutalnym świecie, 
        gdzie na każdym kroku czekają hordy zombie oraz inni gracze czychający na twoje życie.`
      }
      subHeading={
        `Jedyny taki serwer MineZ w Polsce! 
        Autorskie pluginy zagwarantują Ci świetną zabawę na wiele godzin - buduj bazy, 
        ulepszaj je, rajduj bazy innych oraz walcz z potężnymi przeciwnikami sam, 
        ub ze znajomymi i zbieraj niestandardowe części wyposażenia, aby zwiększyć swoją siłę.`
      }
    />

    <div className="d-flex flex-row justify-content-center align-items-center w-100 py-5">
      <video autoPlay muted loop
        className="col-12 col-md-8 col-lg-6"
        style={{
          WebkitBoxShadow: "0px 0px 14px 0px black",
          BoxShadow: "0px 0px 14px 0px black"
        }}
      >
        <source src={require("../../assets/vids/minez.mp4")}
          type="video/mp4" />
      </video>
    </div>

    <div className={sectionClassName}>
      <div className={sectionItemClassName}>
        <img src={require("../../assets/img/RenderM4.webp")}
          alt=""
          className={imgClassName}
        />
      </div>

      <div className={descriptionSectionItemClassName}>
        <GamemodeDescription icon={<TowerIcon />}
          header="EKSPLORUJ LOKACJE!"
          description={
            `Odwiedzaj nowo odkryte tereny takie jak: zniszczone miasta
            bogate w łupy, safezony, w których możesz handlować z NPC oraz
            wykonywać misje, bazy innych graczy, które możesz rajdować,
            lokacje z mutantami i bossami.`
          }
        />

        <GamemodeDescription icon={<SkullIcon />}
          header="WALCZ Z BOSSAMI!"
          description={
            `Na świecie poukrywani są elitarni przeciwnicy, z których możesz
            zdobyć legendarne łupy, które zapewnią ci przewagę nad innymi
            graczami, pamiętaj jednak że nie jest to wyprawa dla
            żółtodziobów!`
          }
        />
      </div>
    </div>

    <div className={sectionClassName}>
      <div className={descriptionSectionItemClassName}>
        <GamemodeDescription
          icon={<MapIcon />}
          header="DYNMAPA!"
          description={
            `Na naszej stronie internetowej znajdziesz mapę, która pomoże Ci
            odnaleźć się w wielkim świecie i dzięki niej będziesz mógł
            ustalić trasę jaką obierzesz.`
          }
        />

        <GamemodeDescription icon={<BookIcon />}
          header="WYKONUJ MISJE!"
          description={
            `Czeka na Ciebie około osiemdziesięciu misji do wykonania w
            różnych zakątkach świata. Im trudniejsza misja tym lepsza
            nagroda, wejdź do gry i poznaj fabułę tego świata.`
          } />
      </div>

      <div className={sectionItemClassName}>
        <img src={require("../../assets/img/adventurer.webp")}
          alt=""
          className={imgClassName}
        />
      </div>
    </div>

    <div className="d-flex flex-column justify-content-center align-items-center w-75 mt-5">
      <p className="text-white fs-2 fw-bold">
        Poradnik do gry na MineZ
      </p>

      <div className={styles.videoContainer}>
        <iframe src="https://www.youtube.com/embed/Aqyw_zr0Glc"
          title="MineZ poradnik"
          width="100%"
          height="100%"
          allowFullScreen
        />
      </div>
    </div>
  </div>
}

export default Minez;

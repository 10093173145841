import React from "react";

function Tiktok({ vidId }) {
  return (
    <iframe
      title="tiktok jbwm"
      loading="lazy"
      src={`https://www.tiktok.com/embed/v2/${vidId}?lang=pl-PL&amp;referrer=http%3A%2F%2Flocalhost%3A3000%2F&amp;embedFrom=oembed`}
      style={{
        width: "100%",
        minHeight: 800,
        display: "block",
        visibility: "unset",
        maxHeight: 1000,
      }}
    />
  );
}

export default Tiktok;

import React from "react";
import styles from "../../styles/HeroContent.module.scss";
import GameModes from "./GameModes";
import PageTitle from "./PageTitle";

function HeroContent({ coords }) {
  const scrollToElem = React.useRef(null);

  const executeScroll = () => scrollToElem.current.scrollIntoView();

  return (
    <div className="container" id={styles.heroContentContainer}>
      <PageTitle scrollToFunc={executeScroll} coords={coords} />
      <GameModes refer={scrollToElem} />
    </div>
  );
}

export default HeroContent;

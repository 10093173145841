import React from "react";

export default function MapIcon() {
  return (
    <svg
      version="1.0"
      xmlns="http://www.w3.org/2000/svg"
      width="100.000000pt"
      height="100.000000pt"
      viewBox="0 0 512.000000 512.000000"
      preserveAspectRatio="xMidYMid meet"
    >
      <g
        transform="translate(0.000000,512.000000) scale(0.100000,-0.100000)"
        fill="#fff"
        stroke="none"
      >
        <path
          d="M43 4990 c-12 -5 -26 -18 -32 -29 -14 -26 -15 -4424 -1 -4450 5 -10
22 -23 37 -29 15 -6 400 -90 856 -186 l827 -175 830 175 830 176 453 -96 c248
-53 466 -96 484 -96 56 0 87 85 44 124 -12 11 -160 47 -437 106 -231 49 -429
92 -441 95 -21 5 -23 11 -23 83 l0 78 634 -134 c425 -91 642 -133 660 -128 59
15 56 -96 56 1944 0 1277 -3 1879 -10 1899 -6 17 -21 35 -33 42 -12 6 -287 67
-612 135 -324 69 -614 130 -642 136 l-53 12 0 80 c0 71 2 79 18 74 9 -3 339
-73 732 -156 393 -83 723 -153 732 -156 17 -5 18 -114 18 -2115 l0 -2109 -22
5 c-116 26 -217 45 -239 45 -32 0 -69 -41 -69 -75 0 -51 25 -63 220 -105 101
-22 193 -37 203 -34 9 3 27 15 37 27 20 21 20 47 20 2230 0 1394 -4 2215 -10
2226 -5 10 -22 23 -37 29 -15 7 -400 90 -855 187 l-827 175 -828 -176 -828
-175 -75 15 c-41 8 -275 58 -520 110 -674 143 -1067 226 -1071 225 -2 0 -14
-4 -26 -9z m862 -320 c391 -82 720 -152 733 -155 20 -5 22 -11 22 -80 0 -90
13 -87 -178 -45 -122 26 -727 155 -998 212 -186 39 -186 38 -182 -329 3 -265
3 -265 27 -284 28 -23 69 -24 99 -3 22 15 22 19 22 240 0 176 3 224 13 224 7
0 278 -56 601 -125 324 -69 591 -125 592 -125 2 0 4 -161 4 -359 l0 -359 -35
-26 c-57 -44 -122 -69 -161 -61 -19 3 -34 9 -34 13 0 4 14 48 32 97 45 129 45
223 1 317 -38 81 -102 146 -181 185 -50 24 -69 27 -152 28 -88 0 -101 -3 -161
-32 -121 -60 -200 -175 -207 -304 -5 -73 17 -165 66 -284 l28 -69 -56 -53
c-70 -65 -116 -148 -131 -239 -10 -58 -9 -83 5 -154 46 -227 47 -241 36 -344
-15 -129 -1 -217 43 -283 42 -62 83 -85 165 -90 121 -9 197 33 377 207 55 53
125 117 155 142 53 44 192 138 204 138 4 0 5 -287 4 -637 l-3 -638 -90 -6
c-175 -13 -255 -38 -333 -104 -104 -90 -172 -281 -172 -484 0 -51 -4 -71 -12
-70 -7 0 -143 28 -303 63 l-290 62 -5 1419 c-3 781 -6 1421 -8 1421 -30 17
-65 24 -87 18 -58 -14 -55 55 -55 -1494 0 -1515 -1 -1479 47 -1494 10 -3 284
-61 608 -130 325 -69 614 -130 643 -136 l52 -12 0 -80 c0 -71 -2 -79 -18 -74
-9 3 -339 73 -732 156 -393 83 -723 154 -733 156 -16 5 -17 114 -17 2115 l0
2109 23 -5 c12 -3 342 -73 732 -155z m2413 82 c2 -62 0 -73 -15 -79 -10 -4
-348 -77 -753 -163 l-735 -155 -3 74 c-2 41 1 77 7 80 5 4 209 49 453 100 244
52 574 122 733 156 160 34 295 62 300 61 6 -2 11 -35 13 -74z m2 -1291 l0
-1047 -27 -27 c-52 -49 -95 -60 -260 -65 l-152 -4 -12 47 c-28 109 -141 223
-252 255 -74 20 -201 9 -264 -23 -104 -54 -170 -135 -199 -247 -18 -71 -14
-125 16 -222 l19 -61 -31 -44 c-53 -77 -105 -204 -153 -368 -26 -88 -54 -172
-62 -186 -18 -31 -55 -54 -100 -64 l-33 -7 0 679 0 679 76 43 c150 86 248 180
304 294 91 186 43 343 -133 434 -43 22 -69 27 -149 31 l-98 4 0 319 0 319 23
5 c12 3 195 42 407 86 212 45 538 114 725 154 187 40 343 70 348 68 4 -2 7
-476 7 -1052z m910 896 l435 -93 3 -472 c1 -260 -1 -472 -5 -472 -5 0 -28 14
-53 30 -61 41 -178 89 -259 106 -208 44 -437 -17 -578 -154 -105 -103 -177
-227 -263 -452 l-39 -105 -1 886 0 887 163 -34 c89 -19 358 -76 597 -127z
m-3015 -486 c22 -10 53 -31 68 -47 99 -100 83 -208 -85 -554 l-70 -145 -67
136 c-86 177 -143 330 -149 401 -13 158 157 276 303 209z m785 -484 c61 -32
84 -66 83 -125 -1 -96 -83 -210 -213 -296 l-55 -36 -3 232 c-2 179 1 233 10
240 28 17 134 9 178 -15z m2332 -82 c81 -17 201 -77 281 -141 l57 -46 0 -1224
c0 -881 -3 -1224 -11 -1224 -6 0 -108 21 -227 46 -284 59 -911 192 -939 199
l-23 5 0 719 0 719 40 78 c22 44 69 162 106 264 110 306 176 426 290 520 104
86 268 119 426 85z m-2674 -237 l-3 -221 -93 -56 c-139 -84 -213 -142 -365
-289 -81 -78 -156 -141 -180 -153 -54 -24 -108 -24 -138 0 -26 21 -25 9 -19
281 2 88 -3 135 -18 200 -33 139 -36 172 -18 233 14 48 87 152 98 137 1 -3 32
-63 68 -134 36 -71 73 -140 83 -153 24 -30 79 -31 107 -3 11 11 57 96 103 188
l83 168 44 -14 c56 -17 134 -10 191 16 24 11 47 21 52 21 4 1 6 -99 5 -221z
m951 -607 c48 -25 95 -79 111 -127 27 -80 -13 -217 -140 -479 l-68 -140 -59
125 c-75 155 -128 287 -149 371 -20 76 -11 123 35 186 57 78 183 108 270 64z
m711 -896 c0 -355 -2 -645 -4 -645 -4 0 -1454 -306 -1488 -314 -17 -4 -18 16
-18 319 l0 322 58 12 c68 13 138 55 175 103 37 48 59 102 111 268 47 151 90
261 103 259 5 0 51 -87 103 -192 103 -208 122 -231 177 -212 23 8 41 36 105
160 91 177 160 329 199 440 l28 80 163 6 c103 4 180 12 208 22 89 30 80 99 80
-628z m-1660 -639 c0 -173 -3 -317 -7 -319 -5 -2 -106 17 -225 42 l-218 46 0
87 c0 105 21 222 53 292 54 118 138 160 330 165 l67 1 0 -314z m1660 -241 l0
-74 -72 -16 c-390 -85 -1435 -302 -1440 -299 -11 7 -10 142 1 149 6 3 266 60
578 126 312 66 647 137 743 158 96 20 178 36 183 34 4 -2 7 -37 7 -78z"
        />
        <path
          d="M1110 3722 c-50 -15 -69 -92 -32 -125 25 -23 78 -21 102 3 51 51 -3
143 -70 122z"
        />
        <path
          d="M4025 2251 c-198 -52 -321 -241 -280 -433 19 -94 87 -258 201 -489
89 -179 106 -208 131 -217 21 -7 38 -6 59 3 24 10 43 41 113 181 166 333 223
487 223 599 0 192 -151 350 -343 360 -41 2 -87 1 -104 -4z m146 -152 c74 -23
137 -95 152 -176 11 -59 -44 -222 -155 -454 l-62 -131 -29 59 c-110 217 -188
424 -188 499 0 88 45 154 131 193 51 23 96 26 151 10z"
        />
        <path
          d="M4051 1914 c-24 -30 -26 -43 -10 -78 28 -61 114 -53 133 12 21 73
-76 126 -123 66z"
        />
        <path
          d="M2465 2295 c-30 -30 -32 -64 -4 -99 24 -31 67 -35 97 -8 49 41 19
132 -43 132 -14 0 -37 -11 -50 -25z"
        />
      </g>
    </svg>
  );
}

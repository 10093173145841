import { useNavigate, useParams } from "react-router-dom";
import PlayerTop from "./PlayerTop";
import Statistic from "./Statistic";
import styles from "./UserStats.module.scss";
import { useEffect, useState } from "react";

function UserStats({ gameMode }) {
  const navigate = useNavigate();

  const { username } = useParams();

  const [error, setError] = useState();
  const [userTopStats, setUserTopStats] = useState();
  const [userPersonalStats, setUserPersonalStats] = useState();

  useEffect(() => {
    fetch(
      `https://backend.jbwm.pl/api/stats/${gameMode.toLowerCase()}/rank/${username}`
    )
      .then((response) => {
        if (response.ok) {
          return response.json();
        } else {
          setError(response.status);
        }
      })
      .then((data) => setUserTopStats(data));
  }, [gameMode, username]);

  useEffect(() => {
    fetch(
      `https://backend.jbwm.pl/api/stats/${gameMode.toLowerCase()}/${username}`
    )
      .then((response) => {
        if (response.ok) {
          return response.json();
        } else {
          setError(response.status);
        }
      })
      .then((data) =>
        setUserPersonalStats({
          ...data,
          professions: JSON.parse(data.professions),
        })
      );
  }, [gameMode, username]);

  return (
    <div className="d-flex flex-column justify-content-center align-items-center w-100 text-white mt-5">
      {error ? (
        <>
          <h2>
            {error === 404
              ? "Nie znaleziono gracza!"
              : "Wystąpił nieznany błąd!"}
          </h2>

          <button
            type="button"
            className="btn btn-link"
            style={{
              color: "rgb(255, 128, 0)",
            }}
            onClick={() => navigate(-1)}
          >
            Wróć
          </button>
        </>
      ) : (
        <>
          <div className="d-flex flex-column justify-content-center align-items-center my-5">
            <img
              src={`https://minotar.net/avatar/${username}/75`}
              alt="avatar"
            />

            <h2 className={`${styles.username} pt-2`}>{username}</h2>

            <h5 className="">
              {userPersonalStats ? userPersonalStats["class"] : null}
            </h5>

            <p>
              {userPersonalStats
                ? `ostatnie logowanie: ${new Date(
                    userPersonalStats["lastLogin"]
                  ).toLocaleDateString("pl-PL")}`
                : null}
            </p>

            <button
              type="button"
              className="btn btn-link"
              style={{
                color: "rgb(255, 128, 0)",
              }}
              onClick={() => navigate(-1)}
            >
              Wróć
            </button>
          </div>

          <div className="d-flex flex-column flex-md-row justify-content-center align-items-center align-items-md-start col-12 col-md-9">
            <div className="d-flex flex-wrap justify-content-center align-items-center col-9 col-md-6">
              {userTopStats
                ? Object.entries(userTopStats["rankingRecord"]).map(
                    ([key, value], index) => {
                      return (
                        <PlayerTop
                          key={index}
                          category={userTopStats["translations"][key]}
                          place={value}
                        />
                      );
                    }
                  )
                : null}
            </div>

            <div className="d-flex flex-column justify-content-center align-items-center col-9 col-md-6">
              {userPersonalStats ? (
                <>
                  <Statistic
                    name="level"
                    level={userPersonalStats["level"]}
                    exp={userPersonalStats["experience"]}
                  />

                  {Object.entries(userPersonalStats["professions"]).map(
                    ([key, value], index) => {
                      return (
                        <Statistic
                          key={index}
                          name={key}
                          level={value["level"]}
                          exp={value["exp"]}
                        />
                      );
                    }
                  )}
                </>
              ) : null}
            </div>
          </div>
        </>
      )}
    </div>
  );
}

export default UserStats;

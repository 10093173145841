import React from "react";
import GamemodeHeader from "../../components/gamemode-pages/GamemodeHeader";
import GamemodeName from "../../components/gamemode-pages/GamemodeName";
import DungeonIcon from "../../components/gamemode-pages/skyblock/icons/DungeonIcon";
import SwordIcon from "../../components/gamemode-pages/skyblock/icons/SwordIcon";
import GamemodeDescription from "../../components/gamemode-description/GamemodeDescription";
import PlanetIcon from "../../components/gamemode-pages/skyblock/icons/PlanetIcon";
import UpgradeIcon from "../../components/gamemode-pages/skyblock/icons/UpgradeIcon";

function Skyblock() {
  const sectionClassName = "d-flex flex-column flex-lg-row justify-content-center align-items-center w-75 my-5"
  const sectionItemClassName = "col-12 col-lg-6 mx-5 d-flex justify-content-center align-items-center"
  const descriptionSectionItemClassName = `${sectionItemClassName} d-flex flex-column justify-content-center align-items-center`
  const imgClassName = "col-12 col-lg-8"

  return <div className="d-flex flex-column justify-content-center align-items-center w-100">
    <GamemodeName name={"SkyMMO"} />

    <GamemodeHeader
      heading={
        `Zbuduj i rozwijaj swoją wyspę, aby stała się twoją bezpieczną przystanią, 
        do której będziesz mógł wracać z łupami po ciężkich zmaganiach z wieloma potworami, metinami i bossami.`
      }
      subHeading={
        `Autorskie, niepowtarzalne pluginy pozwolą Ci zagrać na trybie Skyblock jakiego jeszcze nie widziałeś - mierz się z bossami, 
        zbieraj niestandardowe części wyposażenia, ulepszaj swoją wyspę dzięki specjalnym przedmiotom z autorskich pluginów, 
        walcz o wysokie miejsca w rankingach. To wszystko możesz doświadczyć na naszym serwerze!`
      }
    />

    <div className="d-flex flex-row justify-content-center align-items-center w-100 py-5">
      <video autoPlay muted loop
        className="col-12 col-md-8 col-lg-6"
        style={{
          WebkitBoxShadow: "0px 0px 14px 0px black",
          BoxShadow: "0px 0px 14px 0px black"
        }}
      >
        <source src={require("../../assets/vids/skyblock-2.mp4")}
          type="video/mp4" />
      </video>
    </div>

    <div className={sectionClassName}>
      <div className={sectionItemClassName}>
        <img src={require("../../assets/img/renderSword.webp")}
          alt=""
          className={imgClassName}
        />
      </div>

      <div className={descriptionSectionItemClassName}>
        <GamemodeDescription icon={<DungeonIcon />}
          header="OCZYSZCZAJ DUNGEONY!"
          description={
            `Wraz z przyjaciółmi zmagać się możecie z najpotężniejszymi
            dungeonami, w których na Waszej drodze staną bossy z
            innowacyjnymi mechanikami walki.`
          }
        />

        <GamemodeDescription icon={<SwordIcon />}
          header="WBIJAJ SKILLE!"
          description={
            `Posiadamy rozbudowany system umiejętności, dzięki którym Twoja
            postać stanie się silniejsza niż kiedykolwiek, możesz ulepszać
            statystki poprzez walkę, zbieractwo, łowienie i wiele więcej!`
          }
        />
      </div>
    </div>

    <div className={sectionClassName}>
      <div className={descriptionSectionItemClassName}>
        <GamemodeDescription
          icon={<PlanetIcon />}
          header="ODKRYWAJ PLANETY!"
          description={
            `Na naszym trybie możesz przemierzyć różnorodne planety, na
            których znajdują się potwory, kamienie metin oraz potężne bossy,
            które zapewnią ci materiały na ulepszenie postaci.`
          }
        />

        <GamemodeDescription icon={<UpgradeIcon />}
          header="ULEPSZAJ PRZEDMIOTY!"
          description={
            `Możesz ulepszać swoje przedmioty u NPC-tów zgromadzonymi w
            dungeonach ulepszaczami. Przedmioty te są niezniszczalne i nigdy
            ich nie zgubisz.`
          } />
      </div>

      <div className={sectionItemClassName}>
        <img src={require("../../assets/img/koralik.webp")}
          alt=""
          className={imgClassName}
        />
      </div>
    </div>
  </div>
}

export default Skyblock;

import React from "react";
import GamemodeInfo from "../../components/gamemode-info/GamemodeInfo";

function Statspage() {
  const gamemodeContainerClassName = "col-12 col-lg-6 p-2";

  return (
    <div className="d-flex flex-column justify-content-center align-items-center w-100 my-5">
      <h1 className="fancy text-white my-5">
        <span>Statystyki</span>
      </h1>

      <div className="d-flex flex-wrap justify-content-center w-75 my-5">
          <div className={gamemodeContainerClassName}>
              <GamemodeInfo
                  title="SkyMMO"
                  type="video"
                  src={require("../../assets/vids/skyblock-2.mp4")}
                  destination="/statystyki-SkyMMO"
              />
          </div>
        <div className={gamemodeContainerClassName}>
          <GamemodeInfo
            title="MineZ (niedostępne)"
            type="video"
            src={require("../../assets/vids/minez.mp4")}
            destination="#"
          />
        </div>
      </div>
    </div>
  );
}

export default Statspage;

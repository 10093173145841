import React from "react";
import styles from "../../styles/GameModes.module.scss";
import { motion, AnimatePresence } from "framer-motion";
import { useNavigate } from "react-router-dom";

function GameModes({ refer }) {
  const [isOpen, setIsOpen] = React.useState(false);
  let navigate = useNavigate();

  return (
    <>
      <div className="container py-5 mt-5" id={styles.container} ref={refer}>
        <div className="row">
          <div
            className={`col-12 text-center text-white my-5 ${styles.title}`}
            id="lista-serwerow"
          >
            Nasze tryby
          </div>
          <div
            className={`col-xl-6 col-12 d-flex justify-content-center align-items-center my-3 px-sm-5 p-0 ${styles.column}`}
          >
            <div style={{ backgroundColor: "#000" }}>
              <div
                className={styles.videoWrapper}
                onClick={() => navigate("/minez")}
              >
                <video autoPlay muted loop alt="serwer minecraft minez jbwm">
                  <source
                    src={require("../../assets/vids/minez.mp4")}
                    type="video/mp4"
                  />
                </video>
                <div className={styles.gamemodeName}>
                  <div>MINEZ</div>
                </div>
              </div>
            </div>
          </div>
          <div
            className={`col-xl-6 col-12 d-flex justify-content-center align-items-center my-3 px-sm-5 p-0 ${styles.column}`}
          >
            <div style={{ backgroundColor: "#000" }}>
              <div
                className={styles.videoWrapper}
                onClick={() => navigate("/skyblock")}
              >
                <video autoPlay muted loop alt="serwer minecraft skyblobk jbwm">
                  <source
                    src={require("../../assets/vids/skyblock-2.mp4")}
                    type="video/mp4"
                  />
                </video>
                <div className={styles.gamemodeName}>
                  <div>SKYMMO</div>
                </div>
              </div>
            </div>
          </div>

          <AnimatePresence initial={false}>
            {isOpen && (
              <motion.section
                key="content"
                initial="collapsed"
                animate="open"
                exit="collapsed"
                variants={{
                  open: { opacity: 1, height: "auto" },
                  collapsed: { opacity: 0, height: 0 },
                }}
                transition={{ duration: 0.2, ease: [0.66, 1, 0.04, 0.52] }}
                style={{ padding: 0 }}
              >
                <motion.div
                  variants={{
                    collapsed: { opacity: 0 },
                    open: { opacity: 1 },
                  }}
                  transition={{ duration: 0.2 }}
                  className="content-placeholder"
                  style={{
                    display: "flex",
                    flexWrap: "wrap",
                    flexDirection: "row",
                    justifyContent: "space-between",
                  }}
                >
                  {/* <div
                    className={`col-xl-6 col-12 d-flex justify-content-center align-items-center my-3 px-sm-5 p-0 ${styles.column}`}
                  >
                    <div style={{ backgroundColor: "#000" }}>
                      <div
                        className={styles.videoWrapper}
                        onClick={() => navigate("/smp")}
                      >
                        <img
                          src={require("../../assets/img/smp.webp")}
                          width="100%"
                          alt="serwer minecraft smp jbwm"
                        />
                        <div className={styles.gamemodeName}>
                          <div>LIFESTEAL SMP</div>
                        </div>
                      </div>
                    </div>
                  </div> */}
                  <div
                    className={`col-xl-6 col-12 d-flex justify-content-center align-items-center my-3 px-sm-5 p-0 ${styles.column}`}
                  >
                    <div style={{ backgroundColor: "#000" }}>
                      <div
                        className={styles.videoWrapper}
                        onClick={() => navigate("/creative")}
                      >
                        <img
                          src={require("../../assets/img/creative.webp")}
                          width="100%"
                          alt="serwer minecraft creative jbwm"
                        />
                        <div className={styles.gamemodeName}>
                          <div>CREATIVE</div>
                        </div>
                      </div>
                    </div>
                  </div>
                  <div
                    className={`col-xl-6 col-12 d-flex justify-content-center align-items-center my-3 px-sm-5 p-0 ${styles.column}`}
                  >
                    <div style={{ backgroundColor: "#000" }}>
                      <div
                        className={styles.videoWrapper}
                        onClick={() => navigate("/duels")}
                      >
                        <img
                          src={require("../../assets/img/duels.webp")}
                          width="100%"
                          alt="serwer minecraft duels jbwm"
                        />
                        <div className={styles.gamemodeName}>
                          <div>DUELS</div>
                        </div>
                      </div>
                    </div>
                  </div>
                  {/* <div
                    className={`col-xl-6 col-12 d-flex justify-content-center align-items-center my-3 px-sm-5 p-0 ${styles.column}`}
                  >
                    <div style={{ backgroundColor: "#000" }}>
                      <div
                        className={styles.videoWrapper}
                        // onClick={() => navigate("/gildie")}
                        style={{ position: "relative" }}
                      >
                        <img
                          src={require("../../assets/img/gildie.webp")}
                          width="100%"
                          alt="serwer minecraft gildie jbwm"
                        />
                        <div
                          style={{
                            position: "absolute",
                            top: 0,
                            left: 0,
                            width: "100%",
                            height: "100%",
                            background: "rgba(0,0,0,0.6)",
                            display: "flex",
                            justifyContent: "center",
                            alignItems: "center",
                          }}
                        >
                          <p
                            style={{
                              fontSize: 24,
                              fontWeight: 600,
                              color: "#fff",
                            }}
                          >
                            Wkrótce
                          </p>
                        </div>
                        <div className={styles.gamemodeName}>
                          <div>GILDIE</div>
                        </div>
                      </div>
                    </div>
                  </div> */}
                </motion.div>
              </motion.section>
            )}
          </AnimatePresence>
          <motion.button
            onClick={() => setIsOpen(isOpen ? false : true)}
            style={{
              background: "blue",
              padding: 15,
              color: "#fff",
              border: "none",
              backgroundColor: "#d17213",
              borderRadius: 10,
              zIndex: 1,
              width: 280,
              margin: "auto",
              marginTop: 30,
              fontWeight: 500,
            }}
          >
            {isOpen ? "Pokaż mniej trybów" : "Pokaż więcej trybów"}
          </motion.button>
        </div>
      </div>
    </>
  );
}

export default GameModes;

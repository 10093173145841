import React from "react";

export default function ArenaIcon() {
  return (
    <svg
      version="1.0"
      xmlns="http://www.w3.org/2000/svg"
      width="100.000000pt"
      height="100.000000pt"
      viewBox="0 0 512.000000 512.000000"
      preserveAspectRatio="xMidYMid meet"
    >
      <g
        transform="translate(0.000000,512.000000) scale(0.100000,-0.100000)"
        fill="#fff"
        stroke="none"
      >
        <path
          d="M2248 4974 c-161 -80 -304 -156 -316 -167 -58 -54 -69 -137 -27 -199
22 -32 63 -56 295 -173 l270 -135 0 -230 0 -230 -53 0 c-244 0 -789 -40 -1079
-78 l-138 -19 0 476 c0 433 -2 479 -18 511 -12 24 -27 37 -50 44 -31 9 -54 -1
-320 -133 -158 -79 -302 -154 -320 -167 -46 -36 -65 -78 -60 -135 8 -82 21
-92 319 -244 l274 -140 0 -120 0 -120 -60 -13 c-315 -70 -487 -123 -650 -202
-175 -86 -264 -164 -301 -267 -11 -31 -13 -242 -14 -1163 0 -1224 -2 -1178 55
-1296 108 -222 475 -446 946 -578 936 -261 2182 -261 3118 0 471 132 838 356
946 578 57 118 55 72 55 1296 -1 1219 2 1158 -56 1241 -88 128 -346 253 -709
344 l-170 42 -5 516 c-5 469 -7 518 -22 536 -40 47 -51 44 -382 -122 -215
-108 -317 -164 -330 -183 -41 -58 -38 -143 7 -193 12 -13 142 -83 290 -157
l267 -134 0 -115 c0 -63 -2 -115 -5 -115 -3 0 -86 11 -183 25 -318 44 -600 67
-984 81 l-188 7 0 594 c0 489 -2 600 -14 628 -15 35 -42 55 -75 55 -11 0 -152
-66 -313 -146z m222 -284 l0 -200 -202 101 -203 101 195 99 c107 54 198 98
203 98 4 1 7 -89 7 -199z m-1450 -340 l0 -200 -202 101 -203 101 195 99 c107
54 198 98 203 98 4 1 7 -89 7 -199z m2990 0 l0 -200 -202 101 -203 101 195 99
c107 54 198 98 203 98 4 1 7 -89 7 -199z m-1020 -690 c790 -37 1421 -151 1760
-317 104 -52 184 -120 196 -168 24 -98 -175 -222 -516 -320 -119 -34 -419
-100 -424 -93 -2 1 -76 112 -164 244 -88 133 -173 251 -189 264 -74 58 -11 55
-1063 58 -672 2 -981 -1 -1017 -9 -91 -18 -123 -52 -295 -309 -87 -128 -162
-238 -166 -242 -11 -11 -265 42 -423 87 -178 52 -321 111 -407 169 -128 87
-141 142 -53 227 284 274 1572 465 2761 409z m534 -510 c17 -6 68 -73 160
-212 75 -111 134 -203 132 -205 -11 -12 -441 -56 -711 -73 -241 -15 -914 -12
-1160 5 -279 20 -630 57 -641 68 -9 8 269 409 289 417 31 12 1899 13 1931 0z
m-3316 -279 c137 -96 484 -208 857 -276 743 -136 1822 -156 2665 -49 529 66
997 195 1182 325 14 11 29 19 32 19 3 0 6 -431 6 -958 0 -1036 1 -1024 -53
-1109 -26 -40 -152 -164 -187 -183 l-20 -11 0 658 c0 702 0 694 -50 793 -34
67 -120 146 -197 182 -92 44 -210 51 -306 19 -143 -48 -249 -162 -282 -306
-12 -49 -15 -204 -15 -867 l0 -806 -52 -12 c-143 -31 -421 -71 -655 -95 l-63
-6 0 748 c0 820 1 802 -60 923 -59 116 -191 221 -325 256 -27 8 -84 14 -125
14 -234 0 -435 -158 -495 -387 -13 -48 -15 -175 -15 -806 l0 -748 -62 6 c-235
24 -513 64 -655 95 l-53 12 0 806 c0 663 -3 818 -15 867 -33 144 -139 258
-282 306 -96 32 -214 25 -306 -19 -77 -36 -163 -115 -197 -182 -50 -99 -50
-91 -50 -793 l0 -658 -20 11 c-34 18 -161 142 -186 181 -55 88 -54 61 -54
1106 0 530 3 963 6 963 3 0 18 -8 32 -19z m737 -755 c57 -24 100 -63 131 -118
l29 -53 3 -797 c2 -686 0 -798 -12 -798 -27 0 -291 90 -391 133 l-100 44 -3
699 c-3 774 -5 746 62 822 63 72 194 104 281 68z m3430 -10 c59 -27 106 -77
129 -137 15 -40 16 -114 14 -743 l-3 -699 -100 -44 c-100 -43 -364 -133 -391
-133 -12 0 -14 112 -12 798 l3 797 29 53 c67 120 207 166 331 108z m-1674
-178 c72 -33 134 -94 168 -167 l26 -56 3 -761 2 -762 -170 -7 c-93 -4 -246 -4
-340 0 l-170 7 0 742 c0 814 -1 795 60 888 37 56 85 94 159 125 77 32 182 29
262 -9z"
        />
      </g>
    </svg>
  );
}

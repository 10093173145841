import React from "react";
import Herosection from "../../components/hero-section/Herosection";
import styles from "../../styles/Homepage.module.scss";
import TiktokSwiper from "../../components/hero-section/TiktokSwiper";
// import DocumentMeta from "react-document-meta";

function getWindowDimensions() {
  const { innerWidth: width, innerHeight: height } = window;
  return {
    width,
    height,
  };
}

// const meta = {
//   title: "Innowacyjna sieć serwerów minecraft jbwm.pl",
//   description:
//     "Najlepsza sieć serwerów minecraft w Polsce. Wersje 1.8 1.16 1.18 skyblock, minez, survival, bedwars, duels, pvp",
//   meta: {
//     name: {
//       keywords: "jbwm,serwery,minecraft,skyblock",
//     },
//   },
// };

function Homepage() {
  const [windowDimensions, setWindowDimensions] = React.useState(
    getWindowDimensions()
  );

  React.useEffect(() => {
    function handleResize() {
      setWindowDimensions(getWindowDimensions());
    }

    window.addEventListener("resize", handleResize);
    return () => window.removeEventListener("resize", handleResize);
  }, []);

  let { width } = windowDimensions;

  return (
    <>
      {/* <DocumentMeta {...meta} /> */}
      <Herosection />
      <section id={styles.section} className="w-100">
        <div
          className="container-fluid mt-5 bg-white rounded"
          style={{ padding: "50px 0" }}
        >
          <p id={styles.title}>Zobacz co u nas!</p>
          <p id={styles.paragraph}>
            Sprawdź nasze najnowsze wpisy na socialach!
          </p>
          <div className="container">
            <TiktokSwiper />
          </div>
          <div className="container mt-5 pt-5 d-flex justify-content-center text-center">
            {width < 556 ? (
              <iframe
                src="https://www.facebook.com/plugins/page.php?href=https%3A%2F%2Fwww.facebook.com%2FJbwmpl%2F&tabs=timeline&width=320&height=500&small_header=false&adapt_container_width=true&hide_cover=false&show_facepile=true&appId"
                style={{
                  border: "none",
                  overflow: "hidden",
                  display: "block",
                  width: 320,
                  height: 500,
                }}
                scrolling="no"
                frameBorder="0"
                allowFullScreen={true}
                allow="autoplay; clipboard-write; encrypted-media; picture-in-picture; web-share"
                alt="najlepszy serwer minecraft w polsce"
                title="najlepszy serwer minecraft w polsce"
              />
            ) : (
              <iframe
                src="https://www.facebook.com/plugins/page.php?href=https%3A%2F%2Fwww.facebook.com%2FJbwmpl%2F&tabs=timeline&width=800&height=800&small_header=false&adapt_container_width=true&hide_cover=false&show_facepile=true&appId"
                style={{
                  border: "none",
                  overflow: "hidden",
                  display: "block",
                  width: 500,
                  height: 800,
                }}
                scrolling="no"
                frameBorder="0"
                allowFullScreen={true}
                allow="autoplay; clipboard-write; encrypted-media; picture-in-picture; web-share"
                alt="najlepsze serwery minecraft w polsce"
                title="najlepsze serwery minecraft w polsce"
              />
            )}
          </div>
        </div>
      </section>
    </>
  );
}

export default Homepage;

import React from "react";

function GamemodeHeader({ heading, subHeading }) {
  return (
    <div className="container">
      <h1
        className="text-center"
        style={{
          color: "#fff",
          fontSize: 24,
          paddingBottom: 20,
          fontWeight: 500,
        }}
      >
        {heading}
      </h1>
      <h3
        className="text-center"
        style={{ color: "#D8D8D8", fontSize: 18, fontWeight: 300 }}
      >
        {subHeading}
      </h3>
    </div>
  );
}

export default GamemodeHeader;

import styles from "./Description.module.scss";

function GamemodeDescription({ icon, header, description }) {
    return <div className={`${styles.scalable} d-flex flex-column flex-lg-row justify-content-center align-items-center w-100 text-white py-4`}>
        <div className="d-flex justify-content-center align-items-center col-12 col-sm-3">
            {icon}
        </div>

        <div className="d-flex flex-column justify-content-center align-items-center align-items-lg-start ms-4">
            <p className="fw-bold fs-3">{header}</p>

            <p>{description}</p>
        </div>
    </div>
}

export default GamemodeDescription;

import React from "react";

export default function ArmorIcon() {
  return (
    <svg
      version="1.0"
      xmlns="http://www.w3.org/2000/svg"
      width="100.000000pt"
      height="100.000000pt"
      viewBox="0 0 512.000000 512.000000"
      preserveAspectRatio="xMidYMid meet"
    >
      <g
        transform="translate(0.000000,512.000000) scale(0.100000,-0.100000)"
        fill="#fff"
        stroke="none"
      >
        <path
          d="M1560 5109 c-560 -66 -1058 -397 -1334 -887 -138 -243 -217 -528
-220 -789 -1 -132 5 -150 68 -197 26 -20 42 -21 385 -24 l357 -3 73 -882 73
-882 -162 -645 c-117 -467 -160 -654 -155 -677 8 -42 50 -92 92 -109 27 -12
333 -14 1823 -14 1954 0 1830 -4 1882 58 49 59 51 51 -123 743 l-161 644 73
882 73 882 357 3 c343 3 359 4 385 24 72 54 84 109 65 296 -76 725 -574 1320
-1271 1521 -132 38 -273 59 -407 61 -132 1 -150 -5 -197 -68 -20 -25 -21 -46
-27 -279 -5 -200 -9 -263 -23 -307 -54 -168 -178 -315 -326 -388 -111 -55
-183 -72 -300 -72 -116 0 -189 17 -300 72 -145 71 -259 203 -322 375 -19 51
-22 87 -27 315 -6 239 -7 259 -27 284 -43 58 -69 69 -162 71 -48 1 -121 -3
-162 -8z m48 -851 l-3 -553 -23 -47 c-13 -26 -40 -61 -59 -79 -76 -66 -52 -64
-662 -67 l-554 -3 7 53 c4 29 18 100 32 157 119 483 486 874 967 1031 77 25
228 58 270 59 l28 1 -3 -552z m2023 536 c558 -106 1004 -526 1144 -1077 13
-51 26 -118 30 -150 l7 -58 -553 3 c-610 3 -586 1 -662 67 -19 18 -46 53 -59
79 l-23 47 -3 553 c-3 540 -2 552 16 552 11 0 57 -7 103 -16z m-1496 -995 c55
-27 139 -59 188 -72 l87 -22 0 -1132 0 -1133 -27 5 c-16 3 -275 32 -578 66
-302 33 -550 61 -551 62 -2 3 -134 1598 -134 1618 0 14 22 17 168 21 180 6
247 21 342 76 60 34 152 123 191 183 58 92 81 177 87 335 l5 142 61 -50 c34
-27 106 -72 161 -99z m1103 -196 c51 -165 196 -312 361 -364 52 -17 102 -22
234 -26 145 -5 167 -8 167 -22 0 -24 -132 -1616 -135 -1618 -1 -2 -244 -30
-541 -63 -296 -32 -556 -61 -576 -64 l-38 -4 0 1132 0 1131 88 22 c108 28 268
106 347 170 l60 49 6 -137 c5 -95 13 -158 27 -206z m-1331 -2406 l653 -73 662
73 c363 41 664 70 668 66 7 -8 240 -933 240 -953 0 -7 -522 -10 -1570 -10
-1057 0 -1570 3 -1570 10 0 10 227 923 235 948 2 6 10 12 17 12 7 0 307 -33
665 -73z"
        />
      </g>
    </svg>
  );
}

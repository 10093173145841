import React from "react";
import { Swiper, SwiperSlide } from "swiper/react";
import Tiktok from "../Tiktok";

import "swiper/css";
import "swiper/css/pagination";
import "swiper/css/navigation";

import { Autoplay, Navigation, Pagination } from "swiper";

function getWindowDimensions() {
  const { innerWidth: width, innerHeight: height } = window;
  return {
    width,
    height,
  };
}

function TiktokSwiper() {
  const [windowDimensions, setWindowDimensions] = React.useState(
    getWindowDimensions()
  );
  const [tiktoks, setTiktoks] = React.useState([]);

  async function fetchTiktok() {
    try {
      const data = await fetch("https://backend.jbwm.pl/api/tiktok/videos");
      const result = await data.json();

      setTiktoks(result);
    } catch (e) {
      console.log(`TIKTOK ERROR - ${e}`)
    }
  }

  React.useEffect(() => {
    fetchTiktok();
    function handleResize() {
      setWindowDimensions(getWindowDimensions());
    }

    window.addEventListener("resize", handleResize);
    return () => window.removeEventListener("resize", handleResize);
  }, []);

  let { width } = windowDimensions;

  return (
    <Swiper
      spaceBetween={0}
      slidesPerView={width < 1400 ? 1 : 2}
      loop={false}
      navigation={true}
      pagination={{
        enabled: true,
      }}
      modules={[Autoplay, Navigation, Pagination]}
    >
      {tiktoks
        ?.filter((item, index) => index < 11)
        .map((item) => (
          <SwiperSlide key={item}>
            <Tiktok vidId={item} />
          </SwiperSlide>
        ))}
    </Swiper>
  );
}

export default TiktokSwiper;

import React from "react";

export default function MapIcon() {
  return (
    <svg
      version="1.0"
      xmlns="http://www.w3.org/2000/svg"
      height="100pt"
      viewBox="0 0 4000.000000 4000.000000"
      preserveAspectRatio="xMidYMid meet"
    >
      <g
        transform="translate(0.000000,4000.000000) scale(0.100000,-0.100000)"
        fill="#fff"
        stroke="none"
      >
        <path
          d="M13280 34669 c-288 -16 -607 -65 -908 -139 -1088 -269 -2080 -929
-2759 -1835 -778 -1037 -1115 -2306 -953 -3579 96 -745 357 -1596 792 -2574
71 -161 128 -295 127 -296 -2 -2 -1172 -418 -2599 -926 -1427 -507 -2629 -938
-2670 -958 -147 -69 -260 -215 -295 -380 -13 -58 -15 -1309 -15 -9117 0 -8061
2 -9057 15 -9114 65 -276 332 -460 609 -422 58 8 1242 426 5153 1817 2793 994
5098 1813 5123 1821 l45 15 4955 -1812 c2725 -997 4980 -1819 5010 -1828 75
-20 220 -20 293 2 127 37 10436 3708 10497 3738 136 66 252 219 285 375 22
104 22 18137 0 18230 -66 279 -342 470 -615 424 -47 -7 -1828 -636 -5178
-1828 -2809 -1000 -5120 -1817 -5137 -1817 -25 0 -7127 2593 -7141 2606 -2 3
25 83 62 179 406 1068 579 1923 543 2694 -49 1055 -405 2018 -1048 2835 -224
284 -555 616 -841 841 -540 426 -1150 728 -1816 899 -503 130 -1002 178 -1534
149z m774 -1123 c511 -74 907 -199 1318 -417 339 -180 602 -375 892 -658 337
-332 556 -631 761 -1046 256 -517 377 -1010 392 -1586 8 -334 -8 -524 -77
-889 -288 -1540 -1482 -3862 -3276 -6370 -248 -347 -482 -664 -492 -667 -4 -1
-41 44 -82 100 -309 423 -442 608 -574 796 -1511 2148 -2555 4102 -2975 5566
-210 732 -264 1299 -180 1890 157 1107 795 2099 1739 2702 537 343 1099 532
1775 597 103 10 675 -3 779 -18z m20844 -15177 l-3 -8411 -4919 -1749 -4919
-1749 -4964 1815 c-2729 998 -4991 1822 -5025 1830 -75 18 -158 19 -230 4 -29
-6 -2228 -785 -4887 -1730 -2658 -946 -4837 -1719 -4842 -1719 -5 0 -8 3600
-7 8411 l3 8411 2485 884 c1366 486 2487 884 2490 884 4 0 55 -89 115 -197
546 -990 1289 -2153 2053 -3213 336 -466 844 -1133 930 -1223 204 -212 562
-220 770 -16 141 138 888 1141 1374 1844 807 1169 1511 2342 2039 3397 60 120
113 217 117 214 30 -18 7411 -2711 7462 -2722 90 -19 164 -17 251 5 41 11
2236 789 4879 1730 2643 940 4811 1710 4818 1710 10 1 12 -1684 10 -8410z"
        />
        <path
          d="M13475 31784 c-747 -50 -1358 -560 -1540 -1284 -48 -190 -61 -463
-30 -655 88 -547 426 -1009 925 -1260 137 -69 331 -129 505 -156 106 -16 369
-16 475 0 681 105 1227 593 1394 1249 41 160 50 238 50 422 0 273 -39 454
-151 704 -227 503 -716 875 -1264 960 -100 16 -282 25 -364 20z m240 -1119
c194 -50 354 -202 416 -395 30 -91 30 -249 0 -340 -61 -190 -222 -345 -411
-395 -72 -19 -221 -19 -294 0 -160 42 -304 160 -374 306 -46 97 -62 162 -62
259 0 97 16 162 62 259 68 144 204 257 363 302 76 22 221 24 300 4z"
        />
        <path
          d="M22555 20439 c-355 -30 -670 -123 -1080 -318 -397 -189 -869 -558
-1238 -968 -181 -201 -227 -295 -227 -470 0 -251 155 -455 404 -530 74 -23
227 -22 296 1 141 47 181 79 404 319 162 175 379 363 556 484 172 117 277 173
483 257 215 89 343 117 592 131 202 12 304 53 420 170 181 181 212 449 80 670
-67 112 -158 186 -283 233 -79 29 -226 36 -407 21z"
        />
        <path
          d="M25230 19913 c-211 -35 -396 -207 -445 -414 -51 -216 35 -450 209
-573 34 -24 129 -74 211 -111 497 -224 1185 -601 1740 -953 82 -52 177 -108
211 -123 239 -109 525 -25 673 197 116 172 127 375 31 565 -65 128 -157 197
-682 512 -659 396 -1554 855 -1746 896 -51 11 -150 13 -202 4z"
        />
        <path
          d="M30395 17990 c-175 -27 -324 -74 -490 -156 -536 -264 -877 -789 -902
-1390 -24 -564 260 -1112 733 -1417 303 -196 666 -288 1012 -258 220 20 363
58 561 152 408 194 708 531 846 952 159 488 88 999 -198 1422 -234 346 -591
584 -1014 676 -77 16 -141 22 -293 24 -107 2 -222 0 -255 -5z m326 -1100 c178
-33 340 -185 396 -372 25 -84 21 -219 -9 -298 -79 -215 -263 -349 -479 -350
-146 0 -259 44 -365 142 -210 196 -215 524 -11 730 126 127 294 180 468 148z"
        />
        <path
          d="M19135 17094 c-51 -8 -137 -35 -180 -56 -84 -42 -146 -100 -247 -230
-386 -499 -740 -828 -1196 -1116 -81 -51 -259 -149 -397 -219 -228 -116 -255
-132 -315 -192 -73 -74 -120 -160 -145 -262 -74 -314 146 -628 466 -667 88
-11 185 -1 260 27 30 12 131 58 225 104 786 387 1416 914 1971 1648 138 182
172 264 173 414 1 242 -163 460 -397 528 -57 17 -171 28 -218 21z"
        />
        <path
          d="M8333 16825 c-93 -25 -174 -74 -249 -149 -124 -125 -177 -278 -156
-450 14 -111 43 -175 185 -398 l106 -167 -181 -113 c-258 -161 -331 -234 -383
-383 -124 -355 143 -734 517 -735 133 0 217 34 459 186 144 91 172 105 183 93
7 -8 62 -93 121 -189 124 -199 185 -266 291 -322 168 -88 326 -90 502 -6 187
89 304 273 306 483 1 149 -23 207 -189 471 -58 92 -104 168 -103 169 2 1 88
55 192 120 208 130 275 188 329 287 51 91 70 167 70 273 -2 248 -170 468 -408
530 -75 19 -204 19 -278 0 -77 -20 -126 -46 -323 -170 -91 -58 -168 -105 -169
-105 -2 0 -54 82 -117 183 -162 260 -257 348 -424 391 -75 19 -210 20 -281 1z"
        />
        <path
          d="M12385 15198 c-139 -26 -301 -147 -369 -277 -162 -309 -10 -686 318
-786 83 -25 394 -84 681 -129 698 -110 1585 -176 1760 -131 192 49 345 198
400 389 23 78 23 214 0 292 -50 174 -183 317 -351 374 -67 23 -108 27 -409 40
-517 22 -1141 99 -1712 210 -156 31 -230 35 -318 18z"
        />
      </g>
    </svg>
  );
}

import capitalizeFirstLetter from "../../utils/functions";

function PlayerTop({ category, place }) {
  return (
    <div className="d-flex flex-column justify-content-center align-items-center py-2 px-3">
      <h2 className="fw-bold">TOP {place}</h2>

      <p>{capitalizeFirstLetter(category)}</p>
    </div>
  );
}

export default PlayerTop;

import React from "react";

export default function SkullIcon() {
  return (
    <svg
      height="80pt"
      version="1.0"
      xmlns="http://www.w3.org/2000/svg"
      viewBox="0 0 512.000000 512.000000"
      preserveAspectRatio="xMidYMid meet"
    >
      <g
        transform="translate(0.000000,512.000000) scale(0.100000,-0.100000)"
        fill="#fff"
        stroke="none"
      >
        <path
          d="M585 4513 c-118 -43 -192 -107 -242 -213 -21 -44 -28 -74 -31 -147
-3 -72 -8 -93 -19 -93 -33 0 -131 -57 -176 -102 -27 -27 -62 -77 -80 -116 -29
-61 -32 -76 -32 -162 0 -86 3 -101 32 -162 59 -124 159 -202 296 -230 110 -21
241 20 332 103 l44 41 214 -144 214 -143 -13 -37 c-7 -21 -23 -84 -36 -140
-20 -88 -23 -131 -23 -298 0 -168 3 -210 23 -299 24 -108 71 -250 103 -312
l18 -36 -250 -167 -251 -167 -43 40 c-92 85 -221 124 -336 102 -133 -26 -233
-105 -292 -229 -29 -61 -32 -76 -32 -162 0 -86 3 -101 32 -162 18 -39 53 -89
80 -116 45 -45 143 -102 176 -102 11 0 16 -21 19 -93 3 -73 10 -103 31 -148
42 -87 97 -143 185 -186 73 -36 80 -38 177 -38 92 0 105 3 166 32 158 77 252
259 220 424 -7 35 -20 77 -28 93 -12 24 -13 31 -1 41 7 7 127 90 266 183 l253
171 89 -65 88 -65 4 -182 c4 -213 17 -255 97 -330 90 -84 74 -82 676 -82 602
0 586 -2 676 82 80 75 93 117 97 331 l4 182 45 32 c25 17 69 50 98 74 l53 43
269 -181 c147 -99 274 -186 281 -193 12 -10 11 -17 -2 -42 -8 -17 -20 -59 -27
-95 -31 -166 63 -344 220 -421 61 -29 74 -32 166 -32 92 0 105 3 167 32 84 39
155 109 193 188 23 49 29 77 33 153 3 71 8 92 19 92 32 0 126 54 172 99 100
98 140 232 110 372 -20 92 -83 126 -136 73 -22 -22 -24 -31 -19 -62 25 -133
15 -187 -47 -259 -47 -53 -105 -82 -162 -83 -51 0 -87 -18 -102 -52 -11 -21
-10 -33 2 -68 33 -95 12 -202 -55 -268 -46 -47 -85 -64 -157 -70 -54 -4 -70
-1 -117 22 -69 34 -112 84 -133 156 -20 70 -7 145 37 208 34 48 39 93 12 120
-10 9 -151 107 -314 216 -295 198 -297 200 -280 221 38 49 138 182 153 205
l15 23 300 -201 c331 -223 343 -228 386 -171 84 115 161 155 273 144 52 -6 63
-4 83 15 31 30 30 82 -4 109 -54 43 -196 35 -297 -16 -27 -13 -68 -42 -92 -64
l-44 -40 -258 172 c-142 94 -262 175 -267 180 -5 5 4 41 22 87 114 288 134
639 54 924 l-19 67 234 156 235 156 43 -40 c91 -83 222 -124 332 -103 137 28
237 106 296 230 29 61 32 76 32 162 0 86 -3 101 -32 162 -18 39 -53 89 -80
116 -45 45 -143 102 -176 102 -11 0 -16 21 -19 93 -3 73 -10 103 -31 148 -42
87 -97 143 -185 186 -73 36 -80 38 -177 38 -92 0 -105 -3 -167 -33 -76 -37
-152 -113 -187 -187 -46 -99 -50 -222 -10 -314 16 -36 17 -47 7 -57 -7 -6
-103 -72 -213 -147 l-200 -135 -86 82 c-169 159 -345 265 -554 335 -173 58
-280 75 -470 75 -185 0 -295 -17 -455 -70 -135 -44 -171 -63 -189 -96 -33 -59
29 -125 93 -98 93 37 233 81 312 96 132 26 367 24 501 -5 266 -56 488 -176
679 -368 184 -183 306 -413 366 -688 20 -93 17 -400 -5 -502 -78 -363 -271
-646 -587 -861 l-85 -57 -5 -217 c-5 -200 -7 -219 -26 -245 -31 -41 -77 -65
-131 -66 l-48 -2 -5 133 c-4 117 -7 135 -24 148 -28 20 -69 17 -97 -5 -23 -18
-24 -27 -27 -150 l-4 -131 -94 0 -94 0 0 124 c0 110 -2 126 -21 150 -15 19
-30 26 -54 26 -24 0 -39 -7 -54 -26 -19 -24 -21 -40 -21 -150 l0 -124 -95 0
-95 0 0 109 c0 116 -11 166 -40 181 -26 14 -63 12 -86 -4 -17 -13 -20 -31 -24
-148 l-5 -133 -48 2 c-54 1 -100 25 -131 66 -19 26 -21 45 -26 245 l-5 217
-82 55 c-315 212 -508 492 -588 853 -25 110 -30 351 -11 469 31 188 107 382
213 540 52 78 216 254 282 303 49 37 66 71 52 108 -12 30 -39 47 -75 47 -24 0
-164 -108 -225 -174 l-24 -25 -186 126 c-102 69 -192 131 -199 137 -10 10 -9
21 7 57 79 180 -13 413 -197 501 -85 40 -208 49 -287 21z m221 -157 c100 -47
158 -159 136 -266 -7 -30 -25 -74 -42 -98 -32 -45 -38 -88 -17 -114 6 -8 111
-82 232 -163 121 -82 224 -151 228 -155 5 -4 -9 -34 -31 -66 -22 -33 -57 -90
-78 -129 -20 -38 -39 -72 -40 -74 -2 -1 -110 69 -241 158 -265 179 -283 186
-325 130 -13 -18 -42 -51 -65 -75 -110 -112 -292 -88 -378 51 -26 42 -30 58
-30 119 0 50 6 83 19 109 39 79 123 136 201 137 51 0 87 18 102 52 11 21 10
33 -2 68 -76 215 129 410 331 316z m3695 10 c127 -45 192 -191 144 -326 -12
-35 -13 -47 -2 -68 15 -34 51 -52 102 -52 57 -1 115 -30 162 -83 125 -143 50
-360 -137 -399 -105 -21 -191 22 -278 141 -42 56 -58 50 -338 -139 -139 -93
-256 -170 -261 -170 -5 0 -22 28 -38 61 -16 34 -49 93 -73 131 -25 38 -41 73
-36 77 5 4 115 79 244 166 129 86 241 164 248 173 20 26 14 69 -18 114 -88
126 -46 294 92 362 58 29 129 34 189 12z m-3190 -2523 c18 -26 61 -81 95 -123
l62 -77 -281 -189 c-155 -104 -289 -197 -299 -206 -27 -27 -22 -72 12 -120 44
-63 57 -138 37 -208 -63 -215 -353 -245 -452 -47 -29 60 -33 140 -10 207 12
35 13 47 2 68 -15 34 -51 52 -102 52 -57 1 -115 30 -162 83 -125 143 -50 360
137 399 105 21 191 -22 278 -141 43 -57 57 -51 367 159 154 104 281 189 282
190 1 0 17 -21 34 -47z"
        />
        <path
          d="M1890 2839 c-118 -15 -243 -95 -310 -199 -89 -139 -92 -327 -7 -472
32 -54 121 -139 175 -167 226 -115 495 -25 609 206 66 134 56 303 -27 431 -97
150 -256 223 -440 201z m156 -160 c110 -28 204 -154 204 -273 0 -34 -7 -83
-16 -109 -20 -60 -84 -131 -148 -165 -43 -22 -64 -27 -131 -27 -67 0 -88 5
-131 27 -111 58 -170 165 -162 288 10 140 108 247 247 270 48 7 69 6 137 -11z"
        />
        <path
          d="M3035 2838 c-139 -18 -274 -121 -339 -258 -29 -61 -31 -72 -31 -180
0 -112 1 -117 36 -190 134 -278 488 -345 709 -134 278 265 113 734 -270 766
-25 2 -72 0 -105 -4z m203 -179 c108 -52 169 -161 160 -283 -20 -245 -307
-362 -489 -198 -76 68 -94 109 -94 217 0 76 4 97 25 139 54 106 175 171 295
158 22 -2 68 -17 103 -33z"
        />
        <path
          d="M2427 1921 c-86 -88 -95 -114 -52 -156 35 -36 68 -32 118 14 l42 39
42 -39 c50 -46 83 -50 118 -14 43 42 34 68 -52 156 -64 66 -82 79 -108 79 -26
0 -44 -13 -108 -79z"
        />
      </g>
    </svg>
  );
}

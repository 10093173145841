import { useNavigate } from "react-router-dom";
import styles from "./GamemodeInfo.module.scss";

function GamemodeInfo({ title, type, src, destination }) {
    const navigation = useNavigate();

    return <div className={`${styles.gamemodeInfo} position-relative w-100`}
        onClick={() => navigation(destination)}
    >
        {
            type === "image"
                ? <img className="w-100"
                    src={src}
                    alt={title}
                />
                : <video className="w-100"
                    autoPlay
                    muted
                    loop
                >
                    <source src={src}
                        type="video/mp4"
                    />
                </video>
        }

        <span className="position-absolute bottom-0 start-0 w-100 fs-2 text-center text-white bg-black bg-opacity-50 py-3 m-0">{title}</span>
    </div >
}

export default GamemodeInfo;

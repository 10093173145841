import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";

function SocialBox({ icon, link }) {
    return <a href={link} target="_blank" rel="noreferrer">
        <FontAwesomeIcon icon={icon}
            size="2x"
            className="text-white mx-2" />
    </a>
}

export default SocialBox;

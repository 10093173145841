import React from "react";
import GamemodeHeader from "../../components/gamemode-pages/GamemodeHeader";
import GamemodeName from "../../components/gamemode-pages/GamemodeName";
import Image from "../../components/gamemode-pages/Image";
import ArmorIcon from "../../components/gamemode-pages/duels/icons/ArmorIcon";
import MouseIcon from "../../components/gamemode-pages/duels/icons/MouseIcon";
import ArenaIcon from "../../components/gamemode-pages/duels/icons/ArenaIcon";
import GamemodeDescription from "../../components/gamemode-description/GamemodeDescription";

function Duels() {
  const sectionClassName = "d-flex flex-column justify-content-center align-items-center w-50 my-5"

  return <div className="d-flex flex-column justify-content-center align-items-center w-100">
    <GamemodeName name={"DUELS/FFA"} />

    <GamemodeHeader
      heading={
        "Pojedynkuj się z innymi graczami i zwiększaj swój ranking globalny by stać się najlepszym wojownikiem na serwerze!"
      }
      subHeading={""}
    />

    <Image image={require("../../assets/img/duels.webp")} />

    <div className={sectionClassName}>
      <GamemodeDescription icon={<ArenaIcon />}
        header="ARENY FFA"
        description={
          `Wejdź na arenę FFA na której możesz walczyć do upadłego z innymi graczami w trybie każdy na każdego!`
        }
      />

      <GamemodeDescription icon={<MouseIcon />}
        header="KONIEC ZE SPAMIENIEM!"
        description={
          `Irytuje cię że każdy klepie się na macro? Na naszym serwerze walczymy na mechanice pvp 1.9+, 
          dzięki czemu starcia są dużo bardziej skillowe i wymagające.`
        }
      />

      <GamemodeDescription icon={<ArmorIcon />}
        header="KITY"
        description={
          `Znajdziesz u nas wiele różnorakich zestawów do walki aby trenować każdy rodzaj uzbrojenia.`
        }
      />
    </div>
  </div>
}

export default Duels;
